import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Resource } from '../models/resource.model';
import { DataTableOption } from '../models/planned';
import { Timesheet, TimesheetData } from '../models/timesheet.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  route = `${environment.api}`;
  constructor(private http: HttpClient) { }


  generateTimesheet(resource_id: string, mesanno: string) {
    return this.http.post<FormattedResponse<string>>(`${this.route}/resources/${resource_id}/timesheet`, { selectedMonth: mesanno })
  }

  deleteTimesheet(resource_id: string, timesheet_id: string) {
    return this.http.delete<FormattedResponse<string>>(`${this.route}/resources/${resource_id}/timesheet/${timesheet_id}`);
  }

  getAllTimesheet(resource_id: string, params: DataTableOption) {
    return this.http.post<FormattedResponse<any[]>>(`${this.route}/resources/${resource_id}/timesheet`, { dataTableOption: params });
  }

  getTimesheetOverview(id: string) {
    return this.http.get<FormattedResponse<any[]>>(`${this.route}/projects/${id}/timesheets/overview`);
  }

  getTimesheetByResourceId(resource_id: string, timesheet_id: string) {
    return this.http.get<TimesheetData>(`${this.route}/resources/${resource_id}/timesheet/${timesheet_id}`)
  }

  updateTimesheet(resource_id: string, timesheet_id: string, timesheet: Timesheet) {
    return this.http.put<TimesheetData>(`${this.route}/resources/${resource_id}/timesheet/${timesheet_id}`, { json: timesheet })
  }

  exportExcelTimesheet(resource_id: string, timesheet_id: string, params: any): Observable<Blob> {
    return this.http.post(`${this.route}/resources/${resource_id}/timesheet/${timesheet_id}/excel`, params, {
      responseType: 'blob'
    });
  }

  exportExcelSummaryTimesheet(params: any) {
    return this.http.post(`${this.route}/timesheets/excel`, params, {
      responseType: 'blob'
    });
  }

  exportAssignment(params: any) {
    return this.http.post(`${this.route}/assignments/excel`, params, {
      responseType: 'blob'
    });
  }

  getTimesheetDataTable(data: any) {
    return this.http.post(`${this.route}/timesheets`, data);
  }

}
