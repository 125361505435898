import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toast } from 'src/app/app.component';
import { CustomFormComponent } from 'src/app/components/custom-form/custom-form.component';
import { Configuration } from 'src/app/components/custom-form/models/configuration.model';
import { Page } from 'src/app/shared/models/page.model';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { ContractsService } from 'src/app/shared/services/contracts.service';
import { ProfessionalPositionsService } from 'src/app/shared/services/professional-positions.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { ContractDataFormComponent } from '../../components/contract-data-form/contract-data-form.component';
import { Mode } from 'src/app/shared/models/utils.model';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('contractDataForm') contractDataForm!: ContractDataFormComponent;
  contract_id: string = this.activatedRoute.snapshot.params['contract'];
  type: string = '';
  contractId: string = '';


  @Input() type_mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources', active: false }
  ];

  Mode: Mode = Mode.CREATE;
  id: string = "";

  constructor(
    private _resources: ResourcesServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _utils: UtilsServices,
    public contractsService: ContractsService
  ) { }

  ngOnInit() {
    this._resources.resource.subscribe(response => {
      if (response?.id) {
        this.id = response.id;
        if (this.activatedRoute.snapshot.data['type']) {
          this.type = this.activatedRoute.snapshot.data['type'];
        }
        this._utils.onSave.pipe(takeUntil(this.unsubscribe$)).subscribe((value: any) => {
          if (value)
            this.onSubmit();
        })
        this._resources.getResourceById(this.id).subscribe({
          next: (response) => {
            this.routes.push({ name: `${response.data.last_name} ${response.data.first_name}`, path: `app/resources/${this.id}`, active: false });
            this.routes.push({ name: 'Contratti', path: `app/resources/${this.id}`, active: false });
            this.routes.push(
              this.type != 'show' ? { name: 'Nuovo', path: `app/resources/${this.id}/contract/new`, active: true } : { name: 'Info', path: `app/resources/${this.id}/contract/details`, active: true }
            )
          }
        })
      }
    })
  }

  ngAfterViewInit() {
    //MI SOTTOSCRIVO AI CAMBIAMENTI//
    this.contractsService.mode.subscribe(response => {
      this.type_mode.next(response);
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.contractsService.mode.next(Mode.SHOW);
  }

  onSubmit() {
    if (this.type_mode.value == Mode.CREATE) {
        let data = { ...this.contractDataForm.dataContractConfig.form.value };
        if (this.contractDataForm.dataContractConfig.form.valid) {
          this.contractsService.createContract(this.id, data).pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (response) => {
              toast.fire({
                icon: 'success',
                title: 'Contratto creato',
                text: 'Il contratto è stato creato con successo'
              }).then(() => {
                this.contractsService.mode.next(Mode.SHOW);
                this.contractDataForm.dataContractConfig.updateDisabledFields();
                this.router.navigate([`app/resources/${this.id}`])
              });
            }
          })
        } else {
          toast.fire({
            icon: 'error',
            title: 'Errore',
            text: 'Compila tutti i campi'
          });
        }

    } else if (this.type_mode.value == Mode.EDIT) {
      this.contractId = this.router.url.split('/')[5];
      let data = { ...this.contractDataForm.dataContractConfig.form.value };
      if (this.contractDataForm.dataContractConfig.form.valid) {
        this.contractsService.editContract(this.id, this.contractId, data).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (response) => {
            toast.fire({
              icon: 'success',
              title: 'Contratto aggiornato',
              text: 'Il contratto è stato aggiornato con successo'
            }).then(() => {
              this.contractsService.mode.next(Mode.SHOW);
              this.router.navigate([`app/resources/${this.id}`])
            });

          }
        })
      } else {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Compila tutti i campi'
        });
      }
    }
  }
}

