import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mode } from 'src/app/shared/models/utils.model';
import { BaseComponent } from '../base/base.component';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { toast } from 'src/app/app.component';
import { NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Subject, filter, takeUntil } from 'rxjs';
import { parseISO, format } from 'date-fns';
import { AlertService } from 'src/app/shared/helpers/alert.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
  @Input() type = 'progetto';
  @Input() types: any[] = [];
  @Input() mode: Mode = Mode.CREATE;
  @Input() width: string = '80vw';
  @Input() service: any;
  @Input() right: string = '0px';
  @Input() variation: boolean = false;
  @Input() backButton: boolean = true;
  @Input() isHiddenModify: boolean | null = false;
  @Input() adminPanel: boolean = false;
  @Input() addUserUrlAdminPanel: string = '';
  @Input() backToAdminPanel: string = '';

  isVariationConfig: boolean = false;
  currentVariation: boolean = false;
  existingVariation: boolean = false;
  start_date: string = '';

  variation_id: string | null = null;
  oldProjectId: string | null = null;
  variation_pivot_id: string | null = null;


  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  unsubscriber$: Subject<void> = new Subject();

  startVariationForm: FormGroup = this._formBuilder.group({
    start_date: null
  })

  endVariationForm: FormGroup = this._formBuilder.group({
    note: null
  })

  constructor(
    private location: Location,
    public _utils: UtilsServices,
    private _formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService
  ) {
    super();
  }

  override ngOnInit() {
    this.getProject();
    this.urlPathCheck();
  }

  //funzione che controlla se l'ultima parte del path
  urlPathCheck() {
    let urlCheck = this.router.url.split('/').pop();
    urlCheck == 'configuration' ? this.isVariationConfig = true : this.isVariationConfig = false;
  }

  ngAfterViewInit() {
    //funzione che controlla l'ultima parte del path
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.unsubscriber$)).subscribe((event: NavigationEnd) => {

      let lastElement = event.url.split('/').pop();
      lastElement == 'configuration' ? this.isVariationConfig = true : this.isVariationConfig = false;
    });
  }

  goBack() {
    this.location.back();
  }


  save() {
    this.onSubmit.emit(true);
    this._utils.onSave.next(true);
  }

  edit() {
    if (this.service.mode.value == Mode.EDIT) this.service.mode.next(Mode.SHOW);
    else this.service.mode.next(Mode.EDIT);
  }

  changeWidth() {
    this.width = this.width == '80vw' ? '96vw' : '80vw';
  }

  setCreate() {
    if (this.service) {
      this.service.mode.next(Mode.CREATE);
    }
  }

  getProject() {
    if (this.variation) {

      this.service.project.pipe(takeUntil(this.unsubscriber$)).subscribe((res: any) => {
        if (res) {
          //Data inizio Progetto
          const parsedStartDate = parseISO(res.start_date);
          this.start_date = format(parsedStartDate, 'yyyy-MM-dd');

          this.currentVariation = res?.is_variation;
          if (res.old_project_variation.length > 0) {
            this.existingVariation = true;

            //Variation id
            let activeVariation = res.old_project_variation.find((el: any) => el.state == 'active');
            activeVariation ? this.variation_id = activeVariation.new_project_id : null;
            activeVariation ? this.variation_pivot_id = activeVariation.id : null;
          }

          //Id progetto originale
          if (res.new_project_variation.length > 0) {
            let activeVariation = res.new_project_variation.find((el: any) => el.state == 'active');
            activeVariation ? this.oldProjectId = activeVariation.old_project_id : null;
            activeVariation ? this.variation_pivot_id = activeVariation.id : null;
          }
        }
      })
    }
  }

  startVariation() {
    this.service.createVariation({ start_date: this.startVariationForm.value.start_date }).subscribe({
      next: (response: any) => {

        let variation_id = response.data;

        Swal.fire({
          icon: 'success',
          title: 'Variazione avviata con successo',
        }).then(() => { this.router.navigate([`app/projects/${variation_id}`]).then(() => window.location.reload()) });
      },
      error: (response: any) => {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Non è possibile avviare la variazione'
        });
      }
    })

  }

  //Vai alla variazione di progetto
  goToVariation() {
    if (this.variation_id) {
      this.router.navigate([`app/projects/${this.variation_id}`]).then(() => window.location.reload())
    }
  }


  completeVariation() {

    this.alertService
      .alert(
        "Sei sicuro di voler completare la variazione ?",
        'question',
        'Chiusura variazione',
        'Conferma',
        'Annulla'
      )
      .then((result) => {
        if (result.isConfirmed) {

          let completeVariationNote = this.endVariationForm.value.note;

          this.service.completeVariation(this.oldProjectId, this.variation_pivot_id, completeVariationNote).subscribe({
            next: (response: any) => {
              Swal.fire({
                icon: 'success',
                title: 'Variazione completata con successo',
              }).then(() => { this.router.navigate([`app/projects/${response.data.id}`]).then(() => window.location.reload()) });
            },
            error: (response: any) => {
              toast.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Non è possibile completare la variazione'
              });
            }
          })
        }
      });
  }

  //Vai al progetto originale
  goToOriginalProject() {
    if (this.oldProjectId) {
      this.router.navigate([`app/projects/${this.oldProjectId}`]).then(() => window.location.reload())
    }
  }

  ngOnDestroy() {
    this._utils.onSave.next(false);
    this._utils.export.next(false);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  export() {
    this._utils.export.next(true);
  }
}
