import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);

  generateVerificationCode(email: string) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/mail/code/generate`, { email: email });
  }

  login(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/login`, credentials);
  }

  logout() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/logout`);
  }

  me() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/me`);
  }

  generateRecoveryCodeEmail(email: string) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/recoverPassword`, email);
  }

  checkCodePwdChange(data: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/checkTokenPwdChange`, data);
  }

  changePassword(data: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/changePassword`, data);
  }

  changePasswordFirstAcces(data: { email: string, password: string, confirmation_password: string }) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/changePswFirstAccess`, data);
  }

  generateCode(email: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/public/recover/generateCode`, { params: { email } });
  }

  confirmCode(email: string, code: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/public/recover/confirmCode`, { params: { email, code } });
  }

  passwordRecovery(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/recover/password`, credentials);
  }

  getUserEmail(): string {
    return localStorage.getItem('email') ?? localStorage.getItem('emailPasswordRecovery')!;
  }

  setUserEmail(email: string) {
    localStorage.setItem('emailPasswordRecovery', email);
  }

  getCurrentUser(user: User) {
    this.currentUser.next(user);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { context: context });
  }

  getUserById(user_id: string) {
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/users/${user_id}`)
  }

  editUser(user_id: string, data: User) {
    return this.http.put<FormattedResponse<User>>(`${environment.api}/auth/users/${user_id}/update`, data)
  }
}
