import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';
import { Page } from 'src/app/shared/models/page.model';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { DetachmentDataFormComponent } from '../../../components/detachment-data-form/detachment-data-form.component';
import { ResourceProjectServices } from 'src/app/shared/services/resource-project.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { DetachmentsServices } from 'src/app/shared/services/detachments.service';
import { Mode } from 'src/app/shared/models/utils.model';
import { UtilsServices } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-add-detachment.component',
  templateUrl: './add-detachment.component.html',
  styleUrls: ['./add-detachment.component.scss']
})
export class AddDetachmentComponent implements OnInit {
  @ViewChild('detachmentConf') detachmentConf!: DetachmentDataFormComponent;

  private unsubscribe$ = new Subject<void>();
  resource: string = "";
  detachment: string = this.activatedRoute.snapshot.params['detachment'];
  Mode: Mode = Mode.SHOW;

  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources', active: false }
  ];

  loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() type_mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);

  constructor(
    private activatedRoute: ActivatedRoute,
    private _projects: ProjectsService,
    private _resources: ResourcesServices,
    private _utils: UtilsServices,
    public _detachmentService: DetachmentsServices,
    private router: Router
  ) {

    //OTTENGO ID DELLA RISORSA DAL SERVIZIO//
    this._resources.resource.subscribe(response => {
      if (response?.id)
        this.resource = response?.id;
    })

  }

  ngOnInit() {
    this._resources.getResourceById(this.resource).subscribe({
      next: (response) => {
        this.routes.push({ name: `${response.data.first_name} ${response.data.last_name}`, path: `app/resources/${this.resource}`, active: false });
        this.routes.push({ name: 'Distacchi', path: `app/resources/${this.resource}`, active: false });
        this.routes.push({ name: 'Nuovo', path: `app/resources/${this.resource}/detachments/new`, active: true });
        if (this.type_mode.value != Mode.CREATE) {
          this._resources.getDetachmentById(this.resource, this.detachment).subscribe({
            next: (response) => {
              this.detachmentConf.detachmentForm.formValue = response.data;
            }
          })
        }
        this._utils.onSave.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
          if (value) {
            this.onSubmit();
          }
        });
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._detachmentService.mode.next(Mode.SHOW);
  }

  ngAfterViewInit() {
    //MI SOTTOSCRIVO AI CAMBIAMENTI//
    this._detachmentService.mode.subscribe(response => {
      this.type_mode.next(response);
    })
  }

  onSubmit() {
    if (this.type_mode.value == Mode.CREATE) {
      if (this.detachmentConf.detachmentForm.formValue.valid) {
        this._resources.createDetachment(this.resource, this.detachmentConf.detachmentForm.formValue.value).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (response) => {
            toast.fire({
              icon: 'success',
              title: 'Distacco creato',
              text: 'Distacco creato con successo'
            }).then(() => { this.router.navigate([`app/resources/${this.resource}`]) });
          }
        })
      } else {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Compila tutti i campi'
        });
      }
    } else {
      let data = { ...this.detachmentConf.detachmentForm.formValue.value };
      if (this.detachmentConf.detachmentForm.formValue.valid) {
        this._resources.editDetachment(this.resource, this.detachment, data).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (response) => {
            toast.fire({
              icon: 'success',
              title: 'Distacco modificato',
              text: 'Distacco modificato con successo'
            }).then(() => { this.router.navigate([`app/resources/${this.resource}`]) });
          }
        })
      } else {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Compila tutti i campi'
        });
      }
    }
  }
}
