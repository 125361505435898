import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Mode } from '../models/utils.model';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  route = `${environment.api}`;
  mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
  onSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private http: HttpClient
  ) { }

  getContracts(resourceId: string, params?: any) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/resources/${resourceId}/contracts`, { params });
  }

  getContractById(resourceId: string, id: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/resources/${resourceId}/contracts/${id}`);
  }

  createContract(resourceId: string, data: any) {
    return this.http.post<FormattedResponse<any>>(`${this.route}/resources/${resourceId}/contracts`, data);
  }

  editContract(resourceId: string, id: string, data: any) {
    return this.http.put<FormattedResponse<any>>(`${this.route}/resources/${resourceId}/contracts/${id}`, data);
  }

  deleteContract(resourceId: string, id: string) {
    return this.http.delete<FormattedResponse<any>>(`${this.route}/resources/${resourceId}/contracts/${id}`);
  }

}
