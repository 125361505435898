<div class="primary-container">
    <div class="d-flex justify-content-between align-items-start mb-4">
        <h5>Timesheet</h5>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addTimesheetModal">
            <i class="fa-solid fa-plus"></i> Aggiungi
        </button>
    </div>

    <div id="resource-timesheet-container" class="m-2 mt-4" *ngIf="endpoint">
        <drw-data-table [actions]="true" [columns]="columns" [displayedColumns]="displayedColumns"
            [endpointDataSource]="endpoint" [isDataRange]="true" [params]="dataTableOption"
            [renderColumns]="renderColumns " (onDeleteClick)="deleteTimesheet($event)" [actionsButton]="actionButtons"
            (onUpdateClick)="onInfoEvent($event)">
        </drw-data-table>
    </div>
</div>

<!-- * Modal Add Timesheet -->
<div class="modal fade" id="addTimesheetModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Genera Timesheet</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label class="form-label">Mese di riferimento</label>
                    <input class="custom-input" [matDatepicker]="dp" [formControl]="date">
                    <mat-hint>MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <button type="button" #closeModalButton class="btn btn-secondary"
                    data-bs-dismiss="modal">Chiudi</button>
                <button type="button" (click)="generateTimesheet()" class="btn btn-primary">Genera</button>
            </div>
        </div>
    </div>
</div>