import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { Role } from 'src/app/shared/models/utils.model';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'drw-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent extends BaseComponent implements OnInit {
  /**
   * Titolo della sezione
   */
  @Input() title?: string;

  /**
   * L'array del nome delle colonne mostrate nella tabella.
   */
  @Input() displayedColumns!: string[];

  /**
   * L'array del nome delle chiavi da mostrare nella tabella.
   */
  @Input() columns!: string[];

  /**
   * EndPoint della chiamata API per popolare la tabella.
   */
  @Input() endpointDataSource!: string;

  @Input() endpointDataSourceBehavior!: BehaviorSubject<string>;

  /**
   * Params della chiamata API per popolare la tabella.
   */
  @Input() params: any;

  /**
   * Flag booleano che permette di rendere visibile la colonna 'azioni'.
   */
  @Input() actions: boolean = false;

  /**
   * È un oggetto con dei valori booleani che permettono di far visualizzare a schermo i diversi bottoni azioni.
   */
  @Input() actionsButton: any = {
    info: { active: true, entity: null, url: null },
    modify: { active: true, entity: null, url: null },
    trash: { active: true },
  };

  /**
 * Flag booleano che permette di visualizzare il paginator.
 */
  @Input() showPaginator: boolean = true;

  /**
   * Flag booleano che permette di visualizzare il campo di ricerca.
   */
  @Input() isFilter: boolean = false;

  /**
   * Flag booleano che permette di attivare l'ordinamento della tabella.
   */
  @Input() isSortable: boolean = false;

  /**
   * Array di colonne che possono essere soggette ad ordinamento.
   */
  @Input() sortableColumns: string[] = [];

  /**
   * EndPoint della chiamata API per la stampa in formato PDF.
   */
  @Input() endpointPdf!: string;

  /**
   * EndPoint della chiamata API per l'export in formato EXCEL.
   */
  @Input() endpointExcel!: string;

  /**
 * Abilita il data range.
 */
  @Input() isDataRange: boolean = false;

  /**
   * Variabile che permette di ricaricare le colonne.
   */
  @Input() renderColumns!: BehaviorSubject<string>;

  /**
   * Se utilizzare un layout di tabella fisso. L'abilitazione
   * di questa opzione applicherà larghezze di colonna coerenti
   * e ottimizzerà il rendering degli stili permanenti per le tabelle native.
   */
  @Input() fixedLayout: boolean = true;

  /**
   * Variabile che identifica la sezione della tabella Obiettivi di progetto
   */
  @Input() goalTableSection: boolean = false;

  /**
   * Variabile che identifica la sezione della tabella Attività di progetto
   */
  @Input() taskTableSection: boolean = false;

  /**
   * Evento che passa l'id dell'elemento da eliminare.
   */
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id dell'elemento da modificare.
   */
  @Output() onUpdateClick: EventEmitter<any> = new EventEmitter<any>();

  /**
 * Evento che passa l'id dell'elemento
 */
  @Output() onInfoClick: EventEmitter<any> = new EventEmitter<any>();

  dataSource!: MatTableDataSource<any>;

  // * Paginator
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  showFirstLastButtons: boolean = true;

  messages: string[] = []

  searchForm = this.fb.group({ search: '' });

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    if (this.actions && !this.columns.includes('actions')) {
      this.columns?.push('actions');
      this.displayedColumns?.push('');
    }

    let params = { skip: this.pageIndex * this.pageSize, take: this.pageSize };

    Object.entries(params).forEach((param) => {
      const [key, value] = param;
      this.params[key] = value;
    });

    this.endpointDataSourceBehavior?.subscribe((value: string) => {
      this.endpointDataSource = this.endpointDataSourceBehavior.value;
    })


    this.getData(this.params);

    this.renderColumns?.subscribe((value: string) => {
      if (value) this.getData(this.params);
    });


  }

  //Setta il truncate alle colonne internal_id, cup e note
  truncateColumnValue(value: string): boolean {
    if (value && (value.includes('internal_id') || value.toLowerCase().includes('cup') || value.includes('note'))) {
      return true
    }
    return false;
  }


  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.params.skip = this.pageIndex * this.pageSize;
    this.params.take = this.pageSize;

    this.getData(this.params);

    return event;
  }


  getData(params?: any) {
    let string: any = "";
    params = {
      skip: this.pageIndex * this.pageSize,
      take: this.pageSize,
      project_id: this.params.project_id,
      sort: this.params.sort ? this.params.sort : 'updated_at',
      sort_by: params?.active ? params.active : 'updated_at',
    };


    if (this.range.value.start && this.range.value.end) {
      params = {
        skip: this.pageIndex * this.pageSize,
        take: this.pageSize,
        start_date: this.range.value.start,
        end_date: this.range.value.end
      };
    }

    if (this.params.searchColumns && this.searchForm.value.search?.length != 0) {
      params = {
        skip: this.pageIndex * this.pageSize,
        take: this.pageSize,
        search: this.searchForm.value.search,
        searchColumns: this.params.searchColumns,
        project_id: this.params.project_id
      }
      if (this.params.searchColumns && Array.isArray(this.params.searchColumns)) {
        this.params.searchColumns.forEach((element: any) => {
          string = string + "," + element;
        });
        string = string.slice(1);
        params.searchColumns = string;
      }
    }

    this.http
      .get<FormattedResponse<any>>(
        `${environment.api}/${this.endpointDataSource}`,
        { params }
      )
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource<any>(response.data);
          this.length = response.total ?? 0;
        },
      });
  }

  // funzione per formattazione custom dei numeri
  formatNumber(value: any) {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return '';
    }
    let formattedValue = numericValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `€${formattedValue}`;
  }

  deleteElement(row: any) {
    this.onDeleteClick.emit(row);
  }

  updateElement(element: any) {
    this.onUpdateClick.emit(element);
  }

  infoElement(row: any) {
    this.onInfoClick.emit(row);
  }

  sortData(sort: Sort) {
    this.getData(sort);
  }


  resetFilter() {
    this.range.reset()
  }

  sortableHeader(headerName?: string): boolean {
    if (this.isSortable && headerName != 'actions') return true;
    if (
      this.sortableColumns.findIndex((element) => element == headerName) != -1
    )
      return true;
    return false;
  }

  exportData(type: 'excel' | 'pdf') {
    switch (type) {
      case 'excel':
        this.http.get(`${environment}/${this.endpointExcel}`);
        break;
      case 'pdf':
        this.http.get(`${environment}/${this.endpointPdf}`);
        break;
      default:
        break;
    }
  }
}
