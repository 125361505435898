import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourceDataFormComponent } from 'src/app/modules/application/resources/components/resource-data-form/resource-data-form.component';
import { AddCmoComponent } from './pages/add-cmo/add-cmo.component';
import { AddResourceComponent } from './pages/add-resource/add-resource.component';
import { EditCmoComponent } from './pages/edit-cmo/edit-cmo.component';
import { EditResourceComponent } from './pages/edit-resource/edit-resource.component';
import { ResourceDetailsComponent } from './pages/resource-details/resource-details.component';
import { ResourcesListComponent } from './pages/resources-list/resources-list.component';
import { ResourcesComponent } from './resources.component';
import { AddContractComponent } from './pages/add-contract/add-contract.component';
import { AddDetachmentComponent } from './pages/add-detachment/add-detachment.component';
import { EditDetachmentComponent } from './pages/edit-detachment/edit-detachment.component';
import { EditContractComponent } from './pages/edit-contract/edit-contract.component';
import { TimesheetDetailsComponent } from './pages/timesheet-details/timesheet-details.component';
import { DetailContractComponent } from './pages/detail-contract/detail-contract.component';
import { ResourceContractListComponent } from './components/resource-contract-list/resource-contract-list.component';
import { ResourceCmoListComponent } from './components/resource-cmo-list/resource-cmo-list.component';
import { ResourceTimesheetListComponent } from './components/resource-timesheet-list/resource-timesheet-list.component';
import { DetachmentsListComponent } from '../resources/pages/detachments-list/detachments-list.component';

const routes: Routes = [
  {
    path: '', component: ResourcesComponent, children: [
      { path: '', component: ResourcesListComponent },
      { path: 'new', component: AddResourceComponent },
      {
        path: ':resource', component: ResourceDetailsComponent, children: [
          { path: 'registry', component: EditResourceComponent },
          { path: 'cmos', component: ResourceCmoListComponent },
          { path: 'cmos/new', component: AddCmoComponent },
          { path: 'cmos/:cmo', component: AddCmoComponent },
          { path: 'contracts', component: ResourceContractListComponent },
          { path: 'contract/new', component: AddContractComponent },
          { path: 'contract/:contract/details', component: AddContractComponent },
          { path: 'timesheets', component: ResourceTimesheetListComponent },
          { path: 'timesheet/:timesheet/details', component: TimesheetDetailsComponent, data: { type: 'edit' } },
          { path: 'detachments', component: DetachmentsListComponent },
          { path: 'detachments/new', component: AddDetachmentComponent },
          { path: 'detachments/:detachment/edit', component: AddDetachmentComponent },
          { path: '', redirectTo: 'registry', pathMatch: 'full' }
        ]
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }
