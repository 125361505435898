import { Component, OnInit } from '@angular/core';
import { Resource } from 'src/app/shared/models/resource.model';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { DetachmentsServices } from 'src/app/shared/services/detachments.service';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-resource-detachments-list',
  templateUrl: './detachments-list.component.html',
  styleUrls: ['./detachments-list.component.scss']
})
export class DetachmentsListComponent implements OnInit {
  id = "";
  renderColumns: BehaviorSubject<string> = new BehaviorSubject<string>('');
  Mode: Mode = Mode.SHOW;
  endpointDataSource: string | null = null;

  columns: string[] = [
    'start_date',
    'end_date',
    'monthly_hours',
    'project.name',
    'contractual_type.display_name',
    'cmo_amount',
    'effective_amount'
  ];

  displayedColumns: string[] = [
    'Data di inizio',
    'Data di fine',
    'Ore mensili',
    'Progetto',
    'Mansione',
    'Costo Medio Orario',
    'Costo Effettivo'
  ];

  sortableColumns: string[] = [];

  params = {
    sort: 'start_date',
    order: 'desc',
    project_id: this.id
  };

  actionButtons = {
    info: { active: false },
    modify: { active: false },
    trash: { active: true },
  }



  constructor(
    private route: ActivatedRoute,
    private _resources: ResourcesServices,
    private alertService: AlertService,
    private detachmentsService: DetachmentsServices,
    private router: Router) {
    //OTTENGO ID DELLA RISORSA//
    this._resources.resource.subscribe(response => {
      if (response?.id) {
        this.id = response.id;
        this.endpointDataSource = `resources/${this.id}/detachments`;

      }
    })
  }

  ngOnInit(): void {

  }

  deleteElement(element: Resource): void {
    this.alertService
      .alert(
        `Sei sicuro di voler eliminare il distacco al progetto?`, //TODO: <br><b>"${element.last_name} ${element.first_name}"</b>
        'question',
        'Conferma eliminazione',
        'Conferma',
        'Annulla'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this._resources.deleteDetachment(this.id, element.id!).subscribe({
            next: (res) => {
              this.alertService.timerAlert(
                'Distacco eliminato correttamente',
                'success'
              );
              this.renderColumns.next('reload');
            }
          });
        }
      });
  }

  addDistacco() {
    this.detachmentsService.mode.next(Mode.CREATE);
    this.router.navigate(['app' + '/resources/' + this.id + '/detachments/' + 'new']);
  }

  onUpdateEvent(event: any) {
    this.router.navigate([`/app/resources/${event.resource_id}/detachments/${event.id}/edit`]);
  }
}
