import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Detachment } from '../models/detachment.model';
import { BehaviorSubject } from 'rxjs';
import { Mode } from '../models/utils.model';

@Injectable({
    providedIn: 'root'
})
export class DetachmentsServices {
    route = `${environment.api}`;
    detachment: BehaviorSubject<Detachment | null> = new BehaviorSubject<Detachment | null>(null);
    mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
    constructor(private http: HttpClient) { }

    getDetachments(project: string, params?: any) {
        return this.http.get<FormattedResponse<Detachment[]>>(`${this.route}/projects/${project}/detachments`, { params });
    }

    getDetachmentById(project: string, id: string) {
        return this.http.get<FormattedResponse<Detachment>>(`${this.route}/projects/${project}/detachments/${id}`);
    }

    createDetachment(project: string, data: Detachment) {
        return this.http.post<FormattedResponse<Detachment>>(`${this.route}/projects/${project}/detachments`, data);
    }

    editDetachment(project: string, id: string, data: Detachment) {
        return this.http.put<FormattedResponse<Detachment>>(`${this.route}/projects/${project}/detachments/${id}`, data);
    }

    deleteDetachment(project: string, id: string) {
        return this.http.delete<FormattedResponse<Detachment>>(`${this.route}/projects/${project}/detachments/${id}`);
    }
}
