<div>
    <ng-container *ngIf="(timesheetData$ | async)">
        <div class="row col-12">
            <div class="row col-12 justify-content-between align-items-center">
                <div class="col">
                    <h5>Timesheet: {{ (timesheetData$ | async)?.resource?.last_name + " " +
                        (timesheetData$ | async)?.resource?.first_name }}</h5>
                </div>
                <div class="col-auto">
                    <button class="btn btn-secondary ml-auto" (click)="printTimesheet()">
                        <i class="fa-solid fa-file-excel icon-lg mr-2"></i> Esporta
                    </button>
                    <button class="ms-3 btn btn-primary" (click)="updateTimesheet()">
                        <i class="fas fa-save"></i> Salva Timesheet
                    </button>
                </div>
            </div>
            <div class="col-12">
                <label class="subtitle-card">Mese: {{ formatMonth((timesheetData$ | async)?.selectedMonth) }}</label>
            </div>
        </div>
    </ng-container>

    <div class="mt-3">
        <ng-container *ngIf="timesheetData$">
            <app-resource-timesheet-data [data]="timesheetData$" #timesheetData></app-resource-timesheet-data>
        </ng-container>
    </div>
</div>