<footer class="footer" [ngSwitch]="true" [style.width]="width">
    <div [ngClass]="{'mx-4': width != '80vw'}">
        <div *ngSwitchCase="mode == Mode.LIST && types.length == 0"
            class="mb-sm-5 d-flex flex-row justify-content-center">
            <button class="btn btn-primary" [routerLink]="'new'" (click)="setCreate()">
                <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
                Aggiungi {{ type }}</button>
        </div>

        <!--Mode.REPORT-->
        <div *ngSwitchCase="mode == Mode.REPORT && types.length == 0"
            class="mb-sm-5 d-flex flex-row justify-content-start">
            <!-- Button Indietro -->
            <button class="btn btn-outline-primary" (click)="goBack()">
                <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
            </button>
        </div>

        <!--Mode.LIST-->
        <div *ngSwitchCase="mode == Mode.LIST && types.length > 0"
            class="mb-sm-5 d-flex flex-row justify-content-center">
            <div *ngFor="let type of types" class="me-2">
                <button class="btn btn-primary" [routerLink]="type.route" (click)="setCreate()">
                    <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
                    Aggiungi {{ type.types }}</button>
            </div>
        </div>

        <!--Mode.CREATE-->
        <div *ngSwitchCase="mode == Mode.CREATE" class="mb-sm-5 d-flex flex-row justify-content-between">
            <div>
                <!-- Button Indietro -->
                <button class="btn btn-outline-primary" (click)="goBack()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
                </button>
            </div>

            <div>
                <button class="btn btn-primary" (click)="save()" *ngIf="!adminPanel">Salva</button>
            </div>
        </div>

        <div *ngSwitchCase="[Mode.EDIT, Mode.SHOW].includes(mode)" class="mb-sm-5 d-flex flex-row"
            [ngClass]="{'justify-content-between': (_utils.isExportable| async) || variation || backButton, 'justify-content-end': !(_utils.isExportable| async)}">


            <div>
                <!-- Button Indietro -->
                <button *ngIf="backButton" class="btn btn-outline-primary" (click)="goBack()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
                </button>
            </div>

            <div>
                <!-- Button per visualizzare la variazione di progetto -->
                <button *ngIf="existingVariation" class="btn btn-outline-primary btn-or-proj ms-2"
                    (click)="goToVariation()">
                    <span class="me-2"><i class="fas fa-chevron-right"></i></span> Vai alla variazione
                </button>


                <!-- Button per tornare al progetto originale se si è in variazione -->
                <button *ngIf="currentVariation" class="btn btn-outline-primary btn-or-proj ms-2"
                    (click)="goToOriginalProject()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Torna al progetto originale
                </button>

                <button *ngIf="(_utils.isExportable| async)" class="btn btn-outline-primary ms-2" (click)="export()">
                    Esporta .xls
                </button>
            </div>


            <div>
                <!-- Button di avvio variazione -->
                <button [disabled]="existingVariation" *ngIf="variation && !currentVariation"
                    class="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <span nz-icon nzType="branches" nzTheme="outline"></span> Avvia variazione
                </button>

                <!-- Button di fine variazione -->
                <button class="btn btn-outline-primary me-2" *ngIf="variation && currentVariation"
                    data-bs-toggle="modal" data-bs-target="#completeVariation">
                    <span nz-icon nzType="branches" nzTheme="outline"></span>
                    Completa variazione
                </button>

                <button [disabled]="isHiddenModify" class="btn btn-outline-primary" (click)="edit()">
                    <span nz-icon nzType="edit" nzTheme="outline"></span> Modifica
                </button>

                <button class="btn btn-primary ms-2" (click)="save()" [disabled]="mode == Mode.SHOW"
                    *ngIf="!adminPanel && !isVariationConfig"> Salva
                </button>
            </div>

        </div>

        <div *ngSwitchCase="mode == Mode.ADMIN_PANEL" class="mb-sm-5 d-flex flex-row justify-content-center">
            <button class="btn btn-primary" [routerLink]="addUserUrlAdminPanel"><i
                    class="fa-solid fa-circle-plus me-2"></i>Registra nuovo
                utente</button>
        </div>

        <div *ngSwitchCase="mode == Mode.ADMIN_PANEL_ADD_USER" class="mb-sm-5 d-flex flex-row justify-content-between">
            <button class="btn btn-outline-primary" [routerLink]="backToAdminPanel">Indietro</button>

            <button class="btn btn-primary px-4" (click)="save()">Salva</button>
        </div>
    </div>

</footer>

<!-- Modale di inserimento data inizio variazione -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1>Seleziona la data di inizio variazione</h1>
                <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="startVariationForm">
                    <label class="form-label">Data inizio</label>
                    <input [min]="start_date" type="date" class="form-control" formControlName="start_date">
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="startVariation()">Avvia</button>
            </div>
        </div>
    </div>
</div>

<!-- Modale di inserimento nota a completamento variazione -->
<div class="modal fade" id="completeVariation" data-bs-backdrop="static" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header py-4">
                <h1>Inserisci una nota per completare la variazione</h1>
                <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pb-4">
                <form [formGroup]="endVariationForm">
                    <label class="form-label">Nota</label>
                    <textarea id="" class="form-control w-100" rows="8" formControlName="note"></textarea>
                </form>
            </div>
            <div class="modal-footer py-3">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="completeVariation()">Completa variazione</button>
            </div>
        </div>
    </div>
</div>