<div class="container-fluid">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>
  <div class="primary-container mt-4">
    <app-resource-data-form [type]="type" #resourceConf></app-resource-data-form>
  </div>
  <div class="primary-container mt-5">
    <app-contract-data-form [type_mod]="type" #contractDataForm></app-contract-data-form>
  </div>
</div>

<app-footer [width]="'100vw'" [mode]="Mode.CREATE" [type]="'risorsa'"></app-footer>