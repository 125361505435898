import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Resource } from '../models/resource.model';
import { Detachment } from '../models/detachment.model';
import { BehaviorSubject } from 'rxjs';
import { Mode } from '../models/utils.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../helpers/interceptor.service';


@Injectable({
    providedIn: 'root'
})
export class ResourcesServices {
    route = `${environment.api}`;

    resource: BehaviorSubject<Resource | null> = new BehaviorSubject<Resource | null>(null);
    mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


    constructor(private http: HttpClient) { }

    getResources(params?: any) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/resources`, { params });
    }

    getResourcesByFilter(params?: any) {
        let config = new HttpContextConfig();
        config.noSpinner = true;
        const context = new HttpContext().set(CONFIG, config);
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/resources`, { params, context: context });
    }


    getResourcesByCompany(id?: any) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/resourcescompany/${id}`);
    }

    getResourceById(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}`);
    }

    createResource(data: Resource) {
        return this.http.post<FormattedResponse<any>>(`${this.route}/resources`, data);
    }

    editResource(id: string, data: Resource) {
        return this.http.put<FormattedResponse<any>>(`${this.route}/resources/${id}`, data);
    }

    deleteResource(id: string) {
        return this.http.delete<FormattedResponse<Resource>>(`${this.route}/resources/${id}`);
    }

    getDetachments(resource: string, params?: any) {
        return this.http.get<FormattedResponse<Detachment[]>>(`${this.route}/resources/${resource}/detachments`, { params });
    }

    getDetachmentById(resource: string, id: string) {
        return this.http.get<FormattedResponse<Detachment>>(`${this.route}/resources/${resource}/detachments/${id}`);
    }

    createDetachment(resource: string, data: Detachment) {
        return this.http.post<FormattedResponse<Detachment>>(`${this.route}/resources/${resource}/detachments`, data);
    }

    editDetachment(resource: string, id: string, data: Detachment) {
        return this.http.put<FormattedResponse<Detachment>>(`${this.route}/resources/${resource}/detachments/${id}`, data);
    }

    deleteDetachment(resource: string, id: string) {
        return this.http.delete<FormattedResponse<Detachment>>(`${this.route}/resources/${resource}/detachments/${id}`);
    }

    getResourcesByProject(project_id: string) {
        return this.http.get<FormattedResponse<Detachment>>(`${this.route}/projects/${project_id}/resource-project`);
    }

    technologies() {
        return this.http.get<FormattedResponse<any>>(`${this.route}/technologies`);
    }

    getTechnologiesByResource(resource_id: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/resources/${resource_id}/technology`);
    }
}
