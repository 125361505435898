import { ApplicationModule, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesRoutingModule } from './resources-routing.module';
import { ResourcesComponent } from './resources.component';
import { ComponentsModule } from "../../../components/components.module";
import { ResourcesListComponent } from './pages/resources-list/resources-list.component';
import { ResourceDetailsComponent } from './pages/resource-details/resource-details.component';
import { ResourceDataFormComponent } from './components/resource-data-form/resource-data-form.component';
import { AddResourceComponent } from './pages/add-resource/add-resource.component';
import { EditResourceComponent } from './pages/edit-resource/edit-resource.component';
import { ResourceCmoListComponent } from './components/resource-cmo-list/resource-cmo-list.component';
import { AddCmoComponent } from './pages/add-cmo/add-cmo.component';
import { CmoDataFormComponent } from './components/cmo-data-form/cmo-data-form.component';
import { EditCmoComponent } from './pages/edit-cmo/edit-cmo.component';
import { ResourceContractListComponent } from './components/resource-contract-list/resource-contract-list.component';
import { AddContractComponent } from './pages/add-contract/add-contract.component';
import { EditContractComponent } from './pages/edit-contract/edit-contract.component';
import { ContractDataFormComponent } from './components/contract-data-form/contract-data-form.component';
import { AddDetachmentComponent } from './pages/add-detachment/add-detachment.component';
import { EditDetachmentComponent } from './pages/edit-detachment/edit-detachment.component';
import { DetachmentsListComponent } from './pages/detachments-list/detachments-list.component';
import { DetachmentDataFormComponent } from './components/detachment-data-form/detachment-data-form.component';
import { ResourceTimesheetListComponent } from './components/resource-timesheet-list/resource-timesheet-list.component';
import { ResourceTimesheetDataComponent } from './components/resource-timesheet-data/resource-timesheet-data.component';
import { TimesheetDetailsComponent } from './pages/timesheet-details/timesheet-details.component';
import { DetailContractComponent } from './pages/detail-contract/detail-contract.component';

@NgModule({
  imports: [
    CommonModule,
    ResourcesRoutingModule,
    ComponentsModule,
    ApplicationModule
  ],
  declarations: [
    ResourcesComponent,
    ResourceDataFormComponent,
    ResourcesListComponent,
    ResourceDetailsComponent,
    ResourceCmoListComponent,
    AddResourceComponent,
    EditResourceComponent,
    AddCmoComponent,
    CmoDataFormComponent,
    EditCmoComponent,
    AddDetachmentComponent,
    EditDetachmentComponent,
    DetachmentsListComponent,
    DetachmentDataFormComponent,
    ResourceContractListComponent,
    AddContractComponent,
    EditContractComponent,
    ContractDataFormComponent,
    ResourceTimesheetListComponent,
    ResourceTimesheetDataComponent,
    TimesheetDetailsComponent,
    DetailContractComponent,
  ]
})
export class ResourcesModule { }
