<div *ngIf="loading" class="loading-container flex-content-center flex-center">
  <ng-lottie [options]="options" class="d-flex m-auto" style="height: 15rem">
  </ng-lottie>
</div>

<div class="page-body d-flex flex-column justify-content-between">
  <app-header *ngIf="components.header"></app-header>
  <div *ngIf="components.sidebar; else noSidebar" class="d-flex flex-row">
    <!-- <app-sidebar *ngIf="components.sidebar"></app-sidebar> -->
    <router-outlet></router-outlet>
  </div>
  <ng-template #noSidebar>
    <div class="d-flex flex-column flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</div>