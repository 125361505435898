<div class="d-flex flex-row justify-content-between align-items-start">
  <h5 class="my-3" *ngIf="title">{{ title }}</h5>


  <ng-container *ngIf="isDataRange">
    <mat-form-field appearance="outline">
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Inizio">
        <input matEndDate formControlName="end" placeholder="Fine" (dateChange)="getData($event)">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]=" picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-stroked-button class="ms-2" (click)="resetFilter()">Resetta filtri</button>
  </ng-container>

  <div class="d-flex align-items-center" *ngIf="endpointPdf || endpointExcel">
    <button *ngIf="endpointPdf" class="btn btn-outline-danger margin-actions-button" (click)="exportData('pdf')"><i
        class="fa-regular fa-file-pdf"></i> {{'pdf' | uppercase}}</button>
    <button *ngIf="endpointExcel" class="btn btn-outline-success" (click)="exportData('excel')"><i
        class="fa-regular fa-file-excel"></i> {{'excel' | uppercase}}</button>
  </div>

  <div class="d-flex flex-row justify-content-between mb-3" *ngIf="isFilter">
    <!-- * Search field -->
    <form [formGroup]="searchForm" class="mt-3 mx-0 px-0">
      <nz-input-group [nzPrefix]="searchPrefix">
        <input type="text" nz-input formControlName="search" #input placeholder="Cerca" />
        <div nz-col>
          <button class="btn btn-primary" (click)="getData()">Cerca</button>
        </div>
      </nz-input-group>
    </form>
  </div>
</div>

<div>
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" [fixedLayout]="fixedLayout">
    <!-- * Columns -->
    <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortableHeader(column)" class="format-cell"
        [attr.colspan]="column == 'actions'? 1 : 2">
        {{ displayedColumns[i] }}
      </th>
      <td mat-cell *matCellDef="let row" class="format-cell" [attr.colspan]="column == 'actions'? 1 : 2"
        (click)="actionsButton.info.active && column != 'actions' ?  updateElement(row) : null"
        [ngClass]="{'cursor-pointer': actionsButton.info.active && column != 'actions'}"
        [nzTooltipTitle]="column.includes('note') ? getNestedKey(row, column) : '' " nzTooltipPlacement="rightTop"
        nz-tooltip>

        <div [ngSwitch]="true">
          <div class="d-flex flex-row w-50" *ngSwitchCase="actions && column == 'actions'">

            <button *ngIf="actionsButton.modifyBtn && actionsButton.modifyBtn.active" class="btn ant-alert-info me-3"
              (click)="updateElement(row)">
              <span nz-icon nzType="edit" nzTheme="outline"></span>
            </button>

            <button *ngIf="actionsButton.trash.active" class="btn btn-danger" (click)="deleteElement(row)">
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>

          <div *ngSwitchCase="column?.includes('date')">
            <!-- {{ getNestedKey(row, column) | date: 'dd/MM/YYYY'}} -->
            {{ getFormattedDate(row, column) }}
          </div>
          <div
            *ngSwitchCase="['budget', 'total', 'amount', 'effective_cost'].includes(column) || column.includes('amount') || column.includes('budget')">
            {{ formatNumber(getNestedKey(row, column))}}
          </div>
          <div *ngSwitchCase="['Sì', 'No'].includes(row[column])">
            <span *ngIf="(getNestedKey(row, column)) == 'Sì'; else none" nz-icon nzType="check" nzTheme="outline"
              class="text-success"></span>
            <ng-template #none>-</ng-template>
          </div>

          <div *ngSwitchCase="['percentage_allocation'].includes(column)">
            <span
              [ngClass]="[ getNestedKey(row, column) <= 75 ? 'green-alert' : '', getNestedKey(row, column) >= 76 && getNestedKey(row, column) <= 100 ? 'yellow-alert' : '',getNestedKey(row, column) > 100 ? 'red-alert' : '']">{{getNestedKey(row,
              column) }}%</span>
          </div>

          <div *ngIf="goalTableSection">
            <div *ngSwitchCase="['full_code'].includes(column)">
              <span *ngIf="row.alert_budget" [nzTooltipTitle]="'Sono presenti budget negativi in questo obiettivo'"
                nzTooltipPlacement="rightTop" nz-tooltip><i
                  class="fa-solid fa-triangle-exclamation alert-style"></i></span>
              {{getNestedKey(row, column) }}
            </div>
          </div>

          <div *ngIf="taskTableSection">
            <div *ngSwitchCase="['name'].includes(column)">
              <span *ngIf="row.alert_budget" [nzTooltipTitle]="'Sono presenti budget negativi in questa attività'"
                nzTooltipPlacement="rightTop" nz-tooltip><i
                  class="fa-solid fa-triangle-exclamation alert-style"></i></span>
              {{getNestedKey(row, column) }}
            </div>
          </div>


          <div *ngSwitchDefault [ngClass]="truncateColumnValue(column) ? 'truncate': null">
            {{getNestedKey(row, column) }}
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>

    <!-- * Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="100%">
        <p class="text-center">{{'NO_DATA_FOUND' | translate}}</p>
      </td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
    aria-label="Seleziona pagina">
  </mat-paginator>
</div>

<ng-template #searchPrefix>
  <span nz-icon nzType="search" nzTheme="outline"></span>
</ng-template>