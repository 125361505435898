import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { TimesheetService } from 'src/app/shared/services/timesheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { DataTableOption } from 'src/app/shared/models/planned';
import { ResourcesServices } from 'src/app/shared/services/resources.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-resource-timesheet-list',
  templateUrl: './resource-timesheet-list.component.html',
  styleUrls: ['./resource-timesheet-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    //-------------------------------- SETTAGGI FORMATO DATA ITALIANA -----------------------------//
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    //----------------------------- FINE SETTAGGI DATA --------------------------------------------//
  ],


})
export class ResourceTimesheetListComponent implements OnInit {
  date: FormControl = new FormControl
  resource_id: string = ""
  @ViewChild('closeModalButton') closeModalButton!: ElementRef;

  displayedColumns: string[] = ['Mesanno', 'Risorsa', 'Ultimo Aggiornamento'];
  columns: string[] = ['mesanno', 'resource.full_name', 'updated_at'];
  actionButtons = { info: { active: true }, modify: { active: false }, trash: { active: true } };
  endpoint: string | null = null;
  renderColumns: BehaviorSubject<string> = new BehaviorSubject<string>('');
  dataTableOption: DataTableOption;

  constructor(
    private _timesheetService: TimesheetService,
    private route: ActivatedRoute,
    private _alert: AlertService,
    private _resources: ResourcesServices,
    private router: Router
  ) {
    this.dataTableOption = {
      filter: [],
      skip: 0,
      take: 25,
      sort: 'mesanno',
      sortDirection: 'asc',
    }

  }

  ngOnInit() {
    this._resources.resource.subscribe(response => {
      if (response?.id) {
        this.resource_id = response.id;
        this.endpoint = `resources/${this.resource_id}/timesheet`
        this.date = new FormControl(moment(), Validators.required)
      }
    })
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  generateTimesheet() {
    const selectedDate = this.date.value;
    const formattedDate: string = selectedDate.format('YYYY-MM-01');
    this._timesheetService.generateTimesheet(this.resource_id, formattedDate).subscribe({
      next: (response) => {
        Swal.fire({
          icon: 'success',
          title: response.message,
        })
        this.renderColumns.next('reload')
      },
      error: (response) => {
        Swal.fire({
          icon: 'error',
          text: response.error,
        })
      }
    })
    this.closeModalButton.nativeElement.click();
  }

  deleteTimesheet(e: any) {
    this._alert
      .alert(
        `Sei sicuro di voler eliminare il timesheet?`,
        'question',
        'Conferma eliminazione',
        'Conferma',
        'Annulla'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this._timesheetService.deleteTimesheet(this.resource_id, e.id).subscribe({
            next: (res) => {
              this._alert.timerAlert(
                'Contratto eliminato correttamente',
                'success'
              );
              this.renderColumns.next('reload');
            }
          });
        }
      });
  }

  onInfoEvent(event: any) {
    this.router.navigate([`/app/resources/${event.resource.id}/timesheet/${event.id}/details`]);
  }
}
