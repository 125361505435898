<nz-layout class="fixed-height">
  <nz-sider nzCollapsible nzWidth="20vw" (nzCollapsedChange)="changeWidth()" nzBreakpoint="lg">
    <div class=" wrapper">
      <ul nz-menu nzTheme="light" nzMode="inline">
        <li *ngFor="let menu of menus" nz-submenu [nzTitle]="menu.title" [nzIcon]="menu.icon" [(nzOpen)]="menu.open"
          [nzDisabled]="menu.disabled">
          <ul *ngFor="let sub of menu.children">
            <li nz-menu-item [routerLink]="sub.url" [nzSelected]="sub.selected" [nzDisabled]="sub.disabled">
              <span nz-icon [nzType]="sub.icon" [nzTheme]="'outline'"></span>
              <span>{{ sub.title }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nz-sider>
  <nz-layout class="inner-content">
    <nz-content class="my-2">
      <div class="d-flex flex-row justify-content-between">
        <app-breadcrumbs [routes]="routes"></app-breadcrumbs>
      </div>
      <div class="my-4">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>