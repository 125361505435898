import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { Mode } from 'src/app/shared/models/utils.model';
import { ContractsService } from 'src/app/shared/services/contracts.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';

@Component({
  selector: 'app-resource-contract-list',
  templateUrl: './resource-contract-list.component.html',
  styleUrls: ['./resource-contract-list.component.scss']
})
export class ResourceContractListComponent {

  @ViewChild('contractsTable') contractsDataTable!: DataTableComponent;
  renderColumns: BehaviorSubject<string> = new BehaviorSubject<string>('');
  id = this.route.snapshot.params['resource'];

  actionButtons = { info: { active: true }, modify: { active: true }, trash: { active: true } };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _alert: AlertService,
    private _resource: ResourcesServices,
    private contractsService: ContractsService,
  ) {
    // Sottoscrizione agli eventi di navigazione per ottenere l'URL completo
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const fullPath = this.router.url;
      const segments = fullPath.split('/');
      // Assicurati che l'URL abbia abbastanza segmenti per evitare errori
      if (segments.length > 4) {
        this.id = segments[3]; // L'ID è il quarto segmento
      }
    });
    //MI PRENDO DAL SERVIZIO ID DELLA RISORSA//
    this._resource.resource.subscribe(response => {
      if (response?.id) {
        this.id = response.id;
      }
    })
  }

  ngOnInit() {
    // Sottoscrizione agli eventi di navigazione per ottenere l'URL completo
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const fullPath = this.router.url;
      const segments = fullPath.split('/');
      // Assicurati che l'URL abbia abbastanza segmenti per evitare errori
      if (segments.length > 4) {
        this.id = segments[3]; // L'ID è il quarto segmento
      }
    });
    this._resource.resource.subscribe(response => {
      if (response?.id) {
        this.id = response.id;
      }
    })
  }

  addContract() {
    this.contractsService.mode.next(Mode.CREATE);
    this.router.navigate(['app' + '/resources/' + this.id + '/contract/' + 'new']);
  }



  ngAfterContentChecked() {
    // Sottoscrizione agli eventi di navigazione per ottenere l'URL completo
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const fullPath = this.router.url;
      const segments = fullPath.split('/');
      // Assicurati che l'URL abbia abbastanza segmenti per evitare errori
      if (segments.length > 4) {
        this.id = segments[3]; // L'ID è il quarto segmento
        console.log(this.id); // Stampa l'ID estratto
      }
    });
    let datasource = this.contractsDataTable?.dataSource;
    if (datasource) {

      datasource.data.forEach(element => {
        element.professional_position = element.professional_positions.title;
        element.owner_company = element.company.business_name;
      });
    }

  }

  params = {
    sort: 'created_at',
    order: 'desc'
  }

  columns: string[] = [
    'start_date',
    'end_date',
    'month_hours',
    'professional_position',
    'effective_cost',
    'owner_company'
  ];

  displayedColumns: string[] = [
    'Data di inizio',
    'Data di fine',
    'Ore mensili',
    'Mansione',
    'Costo effettivo',
    'Azienda proprietaria della risorsa'
  ];

  infoElement(event: any) {
    this.router.navigate(['app' + '/resources/' + this.id + '/contract/' + event.id + '/details'])
  }


  deleteElement(event: any) {
    this._alert
      .alert(
        `Sei sicuro di voler eliminare il contratto?`,
        'question',
        'Conferma eliminazione',
        'Conferma',
        'Annulla'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.contractsService.deleteContract(this.id, event.id).subscribe({
            next: (res) => {
              this._alert.timerAlert(
                'Contratto eliminato correttamente',
                'success'
              );
              this.renderColumns.next('reload');
            }
          });
        }
      });
  }

}
