import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { CompanyType } from 'src/app/shared/enums/company-type';
import { Page } from 'src/app/shared/models/page.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: User | undefined = undefined;

  //DA MODIFICARE
  isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  pages: Page[] = [
    { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
    { icon: 'folder-open', name: 'Progetti', path: 'app/projects', selected: false },
    { icon: 'team', name: 'Risorse', path: 'app/resources', selected: false },
    { icon: 'shopping', name: 'Aziende e Professionisti', path: 'app/companiesProfessionals', selected: false },
    { icon: 'printer', name: "Stampe", path: "app/prints", selected: false },
    { icon: 'setting', name: "Template", path: "app/templates", selected: false },
    { icon: 'fund', name: 'Report', path: "app/reports", selected: false }
  ];

  version = 'v1.2.4';
  constructor(private authService: AuthService, private router: Router) {
    this.currentUser = this.authService.currentUser.value;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects;

      this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);


      let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()) });
      if (el) el.selected = true;
    });
  }

  ngOnInit(): void {
    let role = localStorage.getItem('role');
    if (role != "ADMIN") {
      this.isAdminSubject.next(false);
    }
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      this.authService.currentUser.next(null);
      localStorage.removeItem('csrf');
      this.router.navigate(['/auth/login']);
    });
  }
}
