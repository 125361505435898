import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Resource } from 'src/app/shared/models/resource.model';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { environment } from 'src/environments/environment';
import { Mode } from 'src/app/shared/models/utils.model';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ProjectFooterComponent } from '../../../projects/components/project-footer/project-footer.component';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { DetachmentsServices } from 'src/app/shared/services/detachments.service';
import { CmosServices } from 'src/app/shared/services/cmos.service';
import { ContractsService } from 'src/app/shared/services/contracts.service';

@Component({
  selector: 'app-resource-details',
  templateUrl: './resource-details.component.html',
  styleUrls: ['./resource-details.component.scss']
})
export class ResourceDetailsComponent extends BaseComponent implements OnInit {
  @ViewChild('resourceFooter') resourceFooter!: ProjectFooterComponent;

  resource: BehaviorSubject<Resource | null> = new BehaviorSubject<Resource | null>(null);
  routes: any[] = [
    {
      name: `Risorse`,
      path: `/app/resources`,
      active: true
    }
  ];

  private unsubscribe$ = new Subject<void>();

  id!: string;
  tab: number = 0;
  environment = environment;
  isHidden: boolean = false;
  @Input() type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


  isCollapsed: boolean = true;
  menus = [
    {
      level: 1,
      icon: 'file-text',
      title: 'Dettagli',
      open: true,
      selected: false,
      disabled: false,
      children: [
        { level: 2, icon: 'profile', title: 'Anagrafica', url: 'registry', selected: true, disabled: false },
        { level: 2, icon: 'setting', title: 'CMO', url: 'cmos', selected: false, disabled: false },
        { level: 2, icon: 'euro', title: 'Contratti', url: 'contracts', selected: false, disabled: false },
        { level: 2, icon: 'ordered-list', title: 'Timesheet', url: 'timesheets', selected: false, disabled: false },
        { level: 2, icon: 'ordered-list', title: 'Distacchi', url: 'detachments', selected: false, disabled: false }
      ]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _utils: UtilsServices,
    public _resources: ResourcesServices,
    public _detachments: DetachmentsServices,
    public _cmos: CmosServices,
    public contracts: ContractsService,


  ) {
    super();

    this.route.data.subscribe(data => { this.isHidden = data['isHidden']; });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects?.split('/').pop();

      this.menus.forEach((menu) => {
        menu.children.filter(el => el.selected == true).forEach(el => el.selected = false);

        let el = menu.children.find(el => el.url == _url);
        if (el) el.selected = true;
      })
    });
  }

  override ngOnInit() {
    this.id = this.route.snapshot.params['resource'];
    this._resources.mode.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.type.next(value);
    });
    this._resources.getResourceById(this.id).subscribe({
      next: (response) => {
        this._resources.resource.next(response.data);
        this.resource.next(response.data);
        this.routes.push({
          name: `${response.data.last_name} ${response.data.first_name}`,
          path: null,
          active: false
        });
      }
    });
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  changeWidth() {
    this.resourceFooter.changeWidth();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._resources.mode.next(Mode.SHOW);
  }

}

