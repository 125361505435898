import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';
import { Page } from 'src/app/shared/models/page.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { ProfessionalPositionsService } from 'src/app/shared/services/professional-positions.service';
import { QualificationsService } from 'src/app/shared/services/qualifications.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { CustomFormComponent } from '../../../../../components/custom-form/custom-form.component';
import { Configuration } from '../../../../../components/custom-form/models/configuration.model';
import { Mode } from 'src/app/shared/models/utils.model';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { AlertService } from 'src/app/shared/helpers/alert.service';

@Component({
  selector: 'app-resource-data-form',
  templateUrl: './resource-data-form.component.html',
  styleUrls: ['./resource-data-form.component.scss']
})
export class ResourceDataFormComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('resourceForm') resourceForm!: CustomFormComponent;
  @Input() type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
  @Input() resource: BehaviorSubject<Resource | null> = new BehaviorSubject<Resource | null>(null);
  @Input() options: any;
  @Input() disabled = false;

  routes: Page[] = [{ name: 'Risorse', path: 'app/resources' }];
  //OGGETTO DA PASSARE AL CUSTOM FORM//
  resourceFormConfig: Configuration = {
    title: "Anagrafica",
    fields: [
      {
        label: 'Cognome',
        key: 'last_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Cognome',
        column: 'col-12 col-md-4',
        disabled: this.type.value == Mode.SHOW ? true : false,
      },
      {
        label: 'Nome',
        key: 'first_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Nome',
        column: 'col-12 col-md-4',
        disabled: this.type.value == Mode.SHOW ? true : false,
      },
      {
        label: "",
        key: "",
        type: "empty",
        validators: [],
        hint: "",
        placeholder: "",
        column: "col-12 col-lg-2"
      },
      {
        label: 'Titolo di studio',
        key: 'qualification_id',
        type: 'select',
        validators: ['required'],
        placeholder: 'Istruzione',
        column: 'col-12 col-md-4',
        options: [],
        disabled: this.type.value == Mode.SHOW ? true : false,
      },
      {
        label: 'Competenze',
        key: 'technologies',
        type: 'multi-select',
        validators: ['required'],
        placeholder: '',
        column: 'col-12 col-md-6',
        min: 16,
        max: 16,
        disabled: this.type.value == Mode.SHOW ? true : false,
        options: []
      }
    ],
  };

  constructor(
    private qualificationService: QualificationsService,
    private activatedRoute: ActivatedRoute,
    public resourcesService: ResourcesServices,
    private router: Router,
    private _utils: UtilsServices,
    private _alert: AlertService,
    private _resource: ResourcesServices,
    private _projectService: ProjectsService,
    private companiesService: CompaniesService
  ) {

  }


  private _getQualificationsOptions() {
    this.qualificationService.getQualifications().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        this.resourceFormConfig!.fields.find(el => el.key === 'qualification_id')!.options =
          [{ label: 'Scegli...', value: null }, ...response.data.map(el => { return { label: el.title, value: el.id } })];
      }
    });
  }

  getAllTechnologies() {
    this.options = [];
    this._resource.technologies().subscribe((response: any) => {
      this.resourceFormConfig!.fields.find(el => el.key === 'technologies')!.options =
        [...response.data.map((el: any) => { return { name: el.name, value: el.value } })];
    })
  }

  getTechnologiesResource() {
    this.resource.subscribe((res: any) => {
      if (res != null) {
        this._resource.getTechnologiesByResource(res?.id).subscribe(res => {
          this.resourceForm.form.patchValue({ technologies: res.data })
        })
      }
    })
  }


  ngOnInit() {
    this._getQualificationsOptions();
    this.getAllTechnologies();
    this.getTechnologiesResource();
    this._utils.onSave.subscribe((value: any) => {
      if (value)
        this.onSubmit();
    })

  }


  onSubmit() {
    if (this.type.value != 'edit') {
      return;
    } else {
      if (this.resource.value?.id) {
        this.resourcesService.editResource(this.resource.value?.id, this.resourceForm.formValue.value).subscribe(response => {
          this._alert.timerAlert(
            'Risorsa modificata correttamente',
            'success'
          );
          this.resourcesService.mode.next(Mode.SHOW);
          this.resourceForm.updateDisabledFields();
        })
      }
    }
  }

  ngAfterViewInit() {
    this.resource.subscribe(value => {
      this.resourceForm!.formValue = value;
    });
    // //MI SOTTOSCRIVO AI CAMBIAMENTI DEL TYPE PASSATO E MODIFICO IL CAMPO DISABLED//
    this.type.subscribe(response => {
      this.resourceFormConfig.fields.forEach((field: any) => {
        field.disabled = response == Mode.SHOW ? true : false;
      });
      this.resourceForm.updateDisabledFields();
    })
  }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
