import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Field } from 'src/app/components/custom-form/models/field.model';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { CustomFormComponent } from 'src/app/components/custom-form/custom-form.component';
import { Configuration } from 'src/app/components/custom-form/models/configuration.model';
import { Detachment } from 'src/app/shared/models/detachment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { Project } from 'src/app/shared/models/project.model';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-detachment-data-form',
  templateUrl: './detachment-data-form.component.html',
  styleUrls: ['./detachment-data-form.component.scss']
})
export class DetachmentDataFormComponent extends BaseComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('detachmentForm') detachmentForm!: CustomFormComponent;
  @ViewChild('detachmentMonthlyForm') detachmentMonthlyForm!: CustomFormComponent;
  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() detachment: BehaviorSubject<Detachment | null> = new BehaviorSubject<Detachment | null>(null);
  @Input() disabled = false;
  @Input() type = 'add';
  @Input() type_mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


  public loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dates: any[] = [];

  detachmentFormConfig: Configuration | null = null;
  detachmentMonthlyFormConfig: Configuration | null = null;

  project!: Project;

  constructor(
    private _resources: ResourcesServices,
    private _projects: ProjectsService,
    private _utils: UtilsServices,
    private _route: ActivatedRoute
  ) {
    super();
    this._getProjectById();
  }

  override ngOnInit() {
    this.createConfig();
    this._getProjects();
    this._getContractualTypes();
  }

  private _getProjectById() {
    let id = this._route.snapshot.params['project'];

    this._projects.getProjectById(id).subscribe({
      next: (response) => {
        this.project = response.data;
      }
    })
  }

  private _getProjects() {
    let params = {
      company_id: null,
      order_by: 'name',
      order_dir: 'asc',
      skip: 0,
      take: 15
    };

    this._projects.getProjects(params).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        let projects = [{ name: 'Seleziona progetto', label: "Seleziona progetto", value: null }, ...this.optionsMap(response.data, ['name'], ['name'], 'id')];
        this.detachmentFormConfig!.fields.find((field: Field) => field.key == 'project_id')!.options = projects;
      }
    });
  }

  ngAfterViewInit() {
    // //MI SOTTOSCRIVO AI CAMBIAMENTI DEL TYPE PASSATO E MODIFICO IL CAMPO DISABLED//
    this.type_mode.subscribe(response => {
      this.detachmentFormConfig?.fields.forEach((field: any) => {
        field.disabled = response == Mode.SHOW ? true : false;
      });
      this.detachmentForm.updateDisabledFields();
    })
  }

  private _getContractualTypes() {
    let params = {
      company_id: null,
      order_by: 'name',
      order_dir: 'asc',
      skip: 0,
      take: 15
    };

    this._utils.getContractualTypes().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        let types = [{ name: 'Seleziona mansione', label: "Seleziona mansione", value: null }, ...this.optionsMap(response.data, ['display_name'], ['name'], 'id')];
        this.detachmentFormConfig!.fields.find((field: Field) => field.key == 'contractual_type_id')!.options = types;
      }
    });
  }

  createConfig() {
    this.detachmentFormConfig = {
      title: "Distacco",
      fields: [
        {
          label: 'Data di inizio',
          key: 'start_date',
          type: 'date',
          validators: ['required'],
          placeholder: 'Data di inizio',
          column: 'col-12 col-md-4',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
        },
        {
          label: 'Data di fine',
          key: 'end_date',
          type: 'date',
          validators: ['required'],
          placeholder: 'Data di fine',
          column: 'col-12 col-md-4',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
        },
        {
          label: 'Progetto',
          key: 'project_id',
          type: 'select',
          validators: ['required'],
          placeholder: 'Progetto',
          column: 'col-12 col-md-4',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
          options: []
        },
        {
          label: 'Mansione',
          key: 'contractual_type_id',
          type: 'select',
          validators: ['required'],
          placeholder: 'Mansione',
          column: 'col-12 col-md-2',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
          options: []
        },
        {
          label: 'Ore mensili',
          key: 'monthly_hours',
          type: 'number',
          validators: ['required'],
          placeholder: 'Ore mensili',
          column: 'col-12 col-md-2',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
          min: 0,
          value: 0
        },
        {
          label: 'Costo Medio Orario',
          key: 'cmo_amount',
          type: 'currency',
          validators: ['required'],
          placeholder: 'Costo Medio Orario',
          column: 'col-12 col-md-4',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
          fiveCurrency: true,
          value: 0
        },
        {
          label: 'Costo Effettivo',
          key: 'effective_amount',
          type: 'currency',
          validators: ['required'],
          placeholder: 'Costo Effettivo',
          column: 'col-12 col-md-4',
          disabled: this.type_mode.value == Mode.SHOW ? true : false,
          fiveCurrency: true,
          value: 0
        }
      ]
    };
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
