import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimesheetData, TimesheetBody } from 'src/app/shared/models/timesheet.model';
import { HttpContext } from '@angular/common/http';
import { CONFIG } from 'src/app/shared/helpers/interceptor.service';
import { Assignment } from 'src/app/shared/models/assignment.model';
import { HttpContextConfig } from 'src/app/shared/models/http-context-config';
import { AssignmentService } from 'src/app/shared/services/assignment.service';
import { el } from 'date-fns/locale';

@Component({
  selector: 'app-resource-timesheet-data',
  templateUrl: './resource-timesheet-data.component.html',
  styleUrls: ['./resource-timesheet-data.component.scss']
})
export class ResourceTimesheetDataComponent implements OnInit {
  @Input() data: BehaviorSubject<TimesheetData | any> = new BehaviorSubject<TimesheetData | any>(null)
  in_loading_hint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hint_assignment$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  headerDef = ['header:firstColumns', 'header:monthColumns', 'header:lastColumns'];

  excludedColumns = ['Malattie', 'Ferie', 'Permessi'];


  constructor(private _assignmentService: AssignmentService) {

  }

  ngOnInit() {

  }

  getFirstHeadRowSpan(headerElement: string) {
    return (headerElement == "monthColumns") ? 1 : 2;
  }

  getFirstHeadColSpan(headerElement: string) {
    if (headerElement == "monthColumns" && !this.data?.value) {
      // * Gestisci il caso in cui this.data.value sia null o undefined
      return null;
    }

    return (headerElement == "monthColumns") ? this.data.getValue()?.timesheet.subheader.length : 1;
  }

  getDay(bodyElement: String) {
    return (Number(bodyElement) - 1);
  }

  getDayClass(day: string) {
    if (day == 'firstColumns' || day == 'lastColumns')
      return null;

    if (!this.data?.value) {
      // * Gestisci il caso in cui this.data.value sia null
      return null;
    }

    const selectedMonthParts = this.data.value.selectedMonth.split('-');
    const year = Number(selectedMonthParts[0]);
    const month = Number(selectedMonthParts[1]);
    const dayOfMonth = Number(day);

    const date = new Date(year, month - 1, dayOfMonth);
    const _day = date.getDay();
    return (_day === 6 || _day === 0) ? 'weekday weekend' : 'weekday';
  }

  getFirstHeaderStyle(headerElement: string) {
    return (headerElement != "lastColumns") ?
      "text-align: center;vertical-align: middle; border-top: 1px solid #898989c4;font-weight: 700 !important;padding: 0; font-size: 13px;"
      : "text-align: center;vertical-align: middle; border-top: 1px solid #898989c4; border-right: 1px solid #898989c4; padding: 10px; width: 65px"
  }

  isDisabled(bodyElement: any) {
    let cell_class = "input-cell";
    if (!this.data.getValue().timesheet.footer.days[Number(bodyElement) - 1].editable)
      cell_class += " disabled-cell";
    return cell_class;
  }

  // doSum(element: TimesheetBody, bodyElement: any) {
  //   if (element.days && Array.isArray(element.days)) {
  //     const dayElement = element.days.find(el => el.day == bodyElement);
  //     if (dayElement) {
  //       dayElement.value = Number(dayElement.value.replace(',', '.'));
  //     }
  //     element.lastColumns.value = element.days.reduce((subtotale, el) => subtotale + Number(el.value), 0);
  //     if (element.lastColumns.value == 0) {
  //       element.lastColumns.value = 0;
  //     }
  //     if (this.data.value.timesheet.footer.days && Array.isArray(this.data.value.timesheet.footer.days)) {
  //       const footerDayElement = this.data.value.timesheet.footer.days.find((el: any) => el.day == bodyElement);
  //       if (footerDayElement) {
  //         footerDayElement.value = this.data.value.timesheet.body
  //           .map((el: any) => el.days[bodyElement - 1])
  //           .reduce((subtotale: number, el: any) => subtotale + Number(el.value), 0);
  //       }
  //     }
  //     this.data.value.timesheet.footer.lastColumns.value = this.data.value.timesheet.footer.days
  //       .reduce((subtotale: number, el: any) => subtotale + Number(el.value), 0);
  //   }
  // }
  doSum(element: TimesheetBody, bodyElement: any) {
    const excludeTitles = [
      'Festività Infrasettimanale',
      'Malattia',
      'Ferie',
      'Permessi',
      'Ore Riservate',
      'Formazione',
      'Trasferta'
    ];

    if (element.days && Array.isArray(element.days)) {
      const dayElement = element.days.find(el => el.day == bodyElement);
      if (dayElement) {
        dayElement.value = Number(dayElement.value.replace(',', '.'));
      }

      element.lastColumns.value = element.days.reduce((subtotale, el) => subtotale + Number(el.value), 0);
      if (element.lastColumns.value == 0) {
        element.lastColumns.value = 0;
      }

      if (this.data.value.timesheet.footer.days && Array.isArray(this.data.value.timesheet.footer.days)) {
        this.data.value.timesheet.footer.days.forEach((footerDayElement: any, dayIndex: number) => {
          const columnValue = this.data.value.timesheet.body
            .map((el: any) => el.days[dayIndex])
            .reduce((subtotale: number, el: any) => subtotale + Number(el.value), 0);

          footerDayElement.value = this.data.value.timesheet.body
            .reduce((subtotale: number, el: any) => {
              if (excludeTitles.includes(el.firstColumns.titolo)) {
                return subtotale - Number(el.days[dayIndex].value);
              } else {
                return subtotale + Number(el.days[dayIndex].value);
              }
            }, 0);
        });
      }

      // Somma verticale includendo sottrazioni per le colonne specifiche
      this.data.value.timesheet.footer.lastColumns.value = this.data.value.timesheet.footer.days
        .reduce((subtotale: number, el: any) => subtotale + Number(el.value), 0);
    }
  }





  getHintValue(id_assignment: string) {
    this.in_loading_hint$.next(true);
    this._assignmentService.getScheduledHoursByAssignmentId(id_assignment).subscribe((res) => {
      this.hint_assignment$.next(res.data);
      this.in_loading_hint$.next(false);
    })
  }
}
