<form [formGroup]="form" *ngIf="onLoad">
  <!-- * Ruolo -->
  <div class="d-flex flex-row justify-content-between mx-0 px-0">
    <h5 class="mx-2 px-1 my-3" *ngIf="config.title">{{ config.title }}</h5>

    <!-- <h5 class="cursor-pointer mt-3" [routerLink]="">
      <span class="badge bg-secondary">Consulta CV</span>
    </h5> -->
  </div>

  <div class="row d-flex flex-row flex-wrap px-0 mx-0">
    <ng-container *ngFor="let field of config.fields">
      <ng-container [ngSwitch]="field?.type" *ngIf="(field?.conditional && field?.condition) || !field?.conditional">
        <!-- * Input text -->
        <ng-container *ngSwitchCase="'text'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input type="text" class="form-control" [formControlName]="field?.key" [id]="field?.key"
              [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
              [placeholder]="field?.placeholder ? field?.placeholder : ''" [minlength]="field?.min ?? null"
              [maxlength]="field?.max ?? null" [readOnly]="field?.readonly"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
            <small *ngIf="field.max">
              {{ this.form.get(field.key)?.value ? this.form.get(field.key)?.value.length : 0 }} / {{ field.max }}
              caratteri
            </small>
          </div>
        </ng-container>

        <!--* Title -->
        <ng-container *ngSwitchCase="'title-h5'">
          <div class="mb-3" [ngClass]="field?.column">
            <h5>{{ field?.label }}</h5>
          </div>
        </ng-container>

        <!-- * Input Date -->
        <ng-container *ngSwitchCase="'date'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input type="date" class="form-control" [formControlName]="field?.key" [id]="field?.key"
              [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
              [placeholder]="field?.placeholder ? field?.placeholder : ''" [min]="field?.min ? field?.min : null"
              [max]="field?.max ? field?.max : null" [readOnly]="field?.readonly"
              (change)="field.change ? field.change($event) : null"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!--* Date-picker-start -->
        <ng-container *ngSwitchCase="'date-picker-start'">
          <div class="mb-3" [ngClass]="field.column">
            <label [for]="field.key" class="form-label">{{ field.label }}</label>
            <div class="d-flex flex-row">
              <input class="custom-input" [min]="field.minValue ? field.minValue : null" [matDatepicker]="picker1"
                placeholder="Inserisci la data di inizio" [formControlName]="field.key">
              <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </div>
          </div>
        </ng-container>


        <!--* Date-picker-end -->
        <ng-container *ngSwitchCase="'date-picker-end'">
          <div class="mb-3" [ngClass]="field.column">
            <div>
              <label [for]="field.key" class="form-label">{{ field.label }}</label>
              <div class="d-flex flex-row">
                <input class="custom-input" [min]="field.minValue ? field.minValue : null" [matDatepicker]="picker2"
                  placeholder="Inserisci la data di fine" [formControlName]="field.key">
                <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- * Input Number -->
        <ng-container *ngSwitchCase="'number'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input type="number" class="form-control" [formControlName]="field?.key" [id]="field?.key"
              [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
              [placeholder]="field?.placeholder ? field?.placeholder : ''" [min]="field.min ? field.min : null"
              [max]="field.max ? field.max : null" [step]="field?.step ? field?.step : null"
              [formControlName]="field?.key" [value]="field?.range" [readOnly]="field?.readonly"
              (keyup)="field.change ? field.change($event) : null" pattern="^\d*(\.\d{0,2})?$"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
          </div>

        </ng-container>

        <!-- * Input Email -->
        <ng-container *ngSwitchCase="'email'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input type="email" class="form-control" [formControlName]="field?.key" [id]="field?.key"
              [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
              [placeholder]="field?.placeholder ? field?.placeholder : ''" [formControlName]="field?.key"
              [value]="field?.range" [readOnly]="field?.readonly">
          </div>
        </ng-container>

        <!-- * Input Password -->
        <ng-container *ngSwitchCase="'password'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>

            <div class="pwd-input">
              <span id="show-pwd" (click)="tooglePassword()"><i class="fa-regular fa-eye"></i></span>
              <input [type]="showPassword ? 'text' : 'password' " class="form-control" [formControlName]="field?.key"
                [id]="field?.key" [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
                [placeholder]="field?.placeholder ? field?.placeholder : ''" [formControlName]="field?.key"
                [value]="field?.range" [readOnly]="field?.readonly">

            </div>
            <div class="pwd-hint">
              <span>{{field.hint}}</span>
            </div>
          </div>

        </ng-container>

        <!-- * Slider -->
        <ng-container *ngSwitchCase="'range'">
          <div [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input type="range" class="form-range" min="0" max="100" step="0.1" [id]="field?.key"
              [formControlName]="field?.key" [value]="field?.value" (change)="field?.change()">
          </div>
        </ng-container>

        <!-- * Ng-Zorro-Slider -->
        <ng-container *ngSwitchCase="'z-range'">
          <div [ngClass]="field?.column">
            <label [for]="field?.key" [nzTooltipTitle]="field.hint" [nzTooltipPlacement]="'right'" class="form-label"
              nz-tooltip>{{field?.label}} </label>
            <div class="mb-3 range-wrap pt-3">
              <nz-slider [nzTooltipVisible]="'default'" [nzTooltipPlacement]="'top'" [formControlName]="field.key"
                [nzDisabled]="field.disabled" [nzMax]="field.max" [nzMin]="0"></nz-slider>
            </div>
          </div>
        </ng-container>


        <!-- * Input Currency -->
        <ng-container *ngSwitchCase="'currency'">
          <div [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <div class="mb-3 input-group">
              <span class="input-group-text">€</span>
              <input type="text" class="form-control" [formControlName]="field?.key" [id]="field?.key"
                (focus)="(form.value[field.key] == 0) ? form.controls[field.key].setValue('') : null"
                (blur)="(form.value[field.key] == '') ? form.controls[field.key].setValue(0) : null"
                [attr.aria-describedby]="field?.key" [placeholder]="field?.placeholder ? field?.placeholder : ''"
                [step]="field?.step ? field?.step : null" [readOnly]="field?.readonly || field?.disabled" currencyMask
                [options]="field.fiveCurrency ? fiveCurrencyOptions : currencyOptions"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}">
            </div>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!-- * Input Percentage -->
        <ng-container *ngSwitchCase="'percentage'">
          <div [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <div class="mb-3 input-group">
              <span class="input-group-text">%</span>
              <input type="text" class="form-control" [formControlName]="field?.key" [id]="field?.key"
                [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
                [placeholder]="field?.placeholder ? field?.placeholder : ''" [min]="field?.min ? field?.min : null"
                [max]="field?.max ? field?.max : null" [step]="field?.step ? field?.step : null"
                [readOnly]="field?.readonly">
            </div>
          </div>
        </ng-container>

        <!-- * Select -->
        <ng-container *ngSwitchCase="'select'">
          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <select class="form-select" [formControlName]="field?.key"
              (input)="field?.event ? field?.event($event): null"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}">
              <option *ngFor="let option of field?.options" [value]="option.value">
                {{ option.label }}</option>
            </select>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!-- * Select Autocomplete-->
        <ng-container *ngSwitchCase="'select-autocomplete'">
          <div class="mb-3" [ngClass]="field?.column" *ngIf="!field?.hidden">
            <label [for]="field?.key" class="form-label">{{field?.label}}</label>
            <input type="text" class="custom-input form-control" aria-label="Number" #search
              [placeholder]="field.placeholder ? field.placeholder : ''" [matAutocomplete]="auto"
              [formControlName]="field.key" (keyup)="onkeyUpChangeAutoComplete(search.value,field)"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of field.filteredOptions" [value]="option"
                (click)="elementAuto(option, field.key)">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!-- * Select Autocomplete Custom-->
        <ng-container *ngSwitchCase="'select-autocomplete-custom'">
          <div class="mb-3" [ngClass]="field?.column" *ngIf="!field?.hidden">
            <label [for]="field?.key" class="form-label">{{field?.label}}</label>
            <input type="text" class="custom-input form-control" aria-label="Number" #search
              [placeholder]="field.placeholder ? field.placeholder : ''" [matAutocomplete]="auto"
              [formControlName]="field.key" (keyup)="field.change ? field.change($event) : null"
              (focus)="field.change ? field.change($event) : null"
              [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of field.filteredOptions" [value]="option"
                (click)="elementAuto(option, field.key)">
                {{option.label}}
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!-- * Multi-Select -->
        <ng-container *ngSwitchCase="'multi-select'">
          <div class="mb-3" [ngClass]="field?.column" *ngIf="!field?.hidden">
            <div>
              <label [for]="field?.key" class="form-label">{{field?.label}}</label>
              <mat-select class="form-select" [formControlName]="field?.key"
                [placeholder]="field.placeholder ? field.placeholder : ''" multiple
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}">
                <mat-option *ngFor="let option of field?.options" [value]="option.value"
                  [disabled]="option.disabled? option.disabled: false">{{option.name}}</mat-option>
              </mat-select>
            </div>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched"
              class="text-danger required-error-msg">
              {{field.label}} richiesto.
            </div>
          </div>
        </ng-container>

        <!--* Multi-select Autocomplete-->
        <ng-container *ngSwitchCase="'multi-select-autocomplete'">
          <div class="mb-3" [ngClass]="field?.column" *ngIf="!field?.hidden">
            <div>
              <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
              <input class="form-control custom-input" [placeholder]="field.placeholder ? field.placeholder : ''"
                #itemInput #autocompleteTrigger="matAutocompleteTrigger"
                (keyup)="onkeyUpChangeAutoComplete(itemInput.value,field)" [formControlName]="field.key"
                [matAutocomplete]="auto"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}" />
              <!-- Visualizza i valori selezionati internamente -->
              <div *ngIf="selectedItem.length > 0" class="selected-values-container">
                <span *ngFor="let selectedOption of selectedItemLabel" class="selected-item">
                  {{ selectedOption }}
                  <button mat-icon-button (click)="remove(selectedOption,field.key)">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </span>
              </div>
              <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFnMulti">
                <mat-option *ngFor="let option of field.filteredOptions" [value]="option"
                  (click)="add(option,field.key)">
                  <mat-checkbox color="primary" style="padding: 0 12px"
                    [checked]="selectedItem.indexOf(option.value) >= 0"></mat-checkbox>
                  {{option.label}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
              {{ field.label }} richiesto.
            </div>
          </div>
        </ng-container>


        <!-- * Checkbox -->
        <ng-container *ngSwitchCase="'checkbox'">
          <div class="mb-3" [ngClass]="field?.column">
            <div class="form-check mt-4 pt-3">
              <input class="form-check-input" type="checkbox" [formControlName]="field?.key" [id]="field?.key"
                [ngClass]="{'no-pointer-events': field?.readonly}">
              <label class="form-check-label" [for]="field?.key"
                [ngClass]="{'no-pointer-events': field?.readonly}">{{field?.label}}</label>
            </div>
          </div>
        </ng-container>

        <!-- * Textarea -->
        <ng-container *ngSwitchCase="'textarea'">
          <div class="mb-3" [ngClass]="field.column">
            <label [for]="field.key" class="form-label">{{ field.label }}</label>
            <textarea class="form-control" [id]="field.key" [placeholder]="field.placeholder ? field.placeholder : ''"
              [formControlName]="field.key" [attr.aria-describedby]="field.key" [readOnly]="field.readonly"
              [maxlength]="field.max ?? null"></textarea>

            <small *ngIf="field.max">
              {{ this.form.get(field.key)?.value ? this.form.get(field.key)?.value.length : 0 }} / {{ field.max }}
              caratteri
            </small>
          </div>
        </ng-container>

        <!--* Multiple Select Mat-Chip-List-->
        <ng-container *ngSwitchCase="'multiple-chip-list'">
          <div class="mb-3" [ngClass]="field?.column">
            <div>
              <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
              <mat-select [formControlName]="field?.key" multiple
                [placeholder]="field.placeholder ? field.placeholder : ''">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let list of field?.value" [removable]="true" (removed)="field?.function(list)">
                      {{list.name}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let option of field?.options" [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'button'">
          <ng-container *ngIf="field.disabled == null || !field.disabled">
            <div [ngClass]="field?.column" class="align-self-end mb-3">
              <button type="button" [ngClass]="field?.class" (click)="field?.function ? field?.function(): null">
                {{field?.label}}
              </button>
            </div>
          </ng-container>
        </ng-container>

        <!--* Chip-List-Basic-->
        <ng-container *ngSwitchCase="'chip-list-basic'">
          <div class="mb-3" [ngClass]="field.column">
            <mat-chip-list [formControlName]="field?.key">
              <mat-chip *ngFor="let option of field?.options" [value]="option">{{option.name}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </ng-container>

        <!-- * Input-Group -->
        <ng-container *ngSwitchCase="'input-group'">
          <div class="mb-3" [ngClass]="field.column">
            <label [for]="field.key" class="form-label">{{ field.label }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default"> {{ field.grouptext }}</span>
              </div>
              <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                [id]="field.key" [placeholder]="field.placeholder ? field.placeholder : ''"
                [formControlName]="field.key" [attr.aria-describedby]="field.key">
            </div>
          </div>
        </ng-container>

        <!-- * Multiple Inputs -->
        <ng-container *ngSwitchCase="'multiple-inputs'">
          <div class="mb-3" [ngClass]="field.column">
            <label class="form-label">{{ field.label }}</label>
            <div class="input-group">
              <input *ngFor="let input of field?.inputs" type="text" class="form-control" class="form-control"
                [id]="input.key" [ngClass]="input.column" [placeholder]="input.placeholder ? input.placeholder : ''"
                [formControlName]="input.key" [attr.aria-describedby]="input.key"
                [ngClass]="input.key == 'prefix_code' ?  'color-prefix-code' : null">
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'multiple-switches'">
          <div class="mb-3" [ngClass]="field.column">
            <label class="form-label mb-3">{{ field.label }}</label>
            <div class="d-flex flex-row">
              <div class="me-4" *ngFor="let input of field?.inputs">
                <nz-switch [formControlName]="input.key" [nzDisabled]="input.disabled"></nz-switch> {{ input.label }}
              </div>
            </div>
          </div>
        </ng-container>

        <!-- * Radio button -->
        <ng-container *ngSwitchCase="'radio'">
          <div class="mb-3" [ngClass]="field.column" *ngIf="!field?.hidden">
            <div [ngClass]="field.key == 'annual_solar_distinct' ? 'text-center' : null ">
              <nz-radio-group class="mx-3 my-3" [formControlName]="field.key">
                <label nz-radio [nzValue]="field.firstRadioValue">{{field.firstRadioLabel}}</label>
                <label nz-radio [nzValue]="field.secondRadioValue">{{field.secondRadioLabel}}</label>
              </nz-radio-group>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'switch'">
          <div class="mb-3" [ngClass]="field.column">
            <label class="form-label mb-3">{{ field.label }}</label>
            <nz-switch [formControlName]="field.key" [nzDisabled]="field.disabled"></nz-switch> {{ field.label }}
          </div>
        </ng-container>

        <!-- * Empty -->
        <ng-container *ngSwitchCase="'empty'">
          <div class="mb-3" [ngClass]="field?.column">
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>

          <div class="mb-3" [ngClass]="field?.column">
            <label [for]="field?.key" class="form-label">{{ field?.label }}</label>
            <input [type]="field?.type" class="form-control" [formControlName]="field?.key" [id]="field?.key"
              [attr.aria-describedby]="field?.key" [readOnly]="field?.disabled"
              [placeholder]="field.placeholder ? field.placeholder : ''" [minlength]="field?.min ?? null"
              [maxlength]="field?.max ?? null">
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</form>