export const config = {
  header: {
    active: true,
    exception: ["/auth/login", "/auth/pwd-recovery"]
  },
  sidebar: {
    active: false,
    exception: []
  },
  footer: {
    active: true,
    exception: []
  },
}
