import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';
import { Page } from 'src/app/shared/models/page.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { QualificationsService } from 'src/app/shared/services/qualifications.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { CustomFormComponent } from '../../../../../components/custom-form/custom-form.component';
import { Configuration } from '../../../../../components/custom-form/models/configuration.model';
import { ResourceDataFormComponent } from '../../components/resource-data-form/resource-data-form.component';
import { Mode } from 'src/app/shared/models/utils.model';
import { ContractDataFormComponent } from '../../components/contract-data-form/contract-data-form.component';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { ContractsService } from 'src/app/shared/services/contracts.service';

@Component({
  selector: 'app-add-resource.component',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.scss']
})
export class AddResourceComponent implements OnInit {
  //TRAMITE VIEW CHIELD RECUPERO I DATI DEL FIGLIO//
  @ViewChild('resourceConf') resourceConf!: ResourceDataFormComponent;
  type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.CREATE);
  @ViewChild('contractDataForm') contractDataForm!: ContractDataFormComponent;
  private unsubscribe$ = new Subject<void>();
  id: string = this.activatedRoute.snapshot.params['resource'];
  Mode = Mode;
  routes: Page[] = [
    { name: 'Risorse', path: '/app/resources', active: true },
    { name: 'Nuovo', path: '/app/resources/new', active: false }
  ];


  private resource: Resource | null = null;

  resourceFormConfig: Configuration = {
    title: "Dati personali",
    fields: [
      {
        label: 'Cognome',
        key: 'last_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Cognome risorsa',
        column: 'col-12 col-md-6',
      },
      {
        label: 'Nome',
        key: 'first_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Nome risorsa',
        column: 'col-12 col-md-6',
      },
      // {
      //   label: 'Codice fiscale',
      //   key: 'fiscal_code',
      //   type: 'text',
      //   validators: ['required'],
      //   placeholder: 'Codice fiscale',
      //   column: 'col-12 col-md-6',
      //   min: 16,
      //   max: 16,
      // },
      // {
      //   label: 'Data di nascita',
      //   key: 'birth_date',
      //   type: 'date',
      //   validators: ['required'],
      //   placeholder: 'Data di nascita',
      //   column: 'col-12 col-md-6'
      // },
      // {
      //   label: 'Email',
      //   key: 'email',
      //   type: 'email',
      //   validators: ['required'],
      //   placeholder: 'Email',
      //   column: 'col-12 col-md-6'
      // },
      // {
      //   label: 'Telefono',
      //   key: 'phone',
      //   type: 'tel',
      //   validators: ['required'],
      //   placeholder: 'Telefono',
      //   column: 'col-12 col-md-6'
      // },
      {
        label: 'Titolo di studio',
        key: 'qualification_id',
        type: 'select',
        validators: ['required'],
        placeholder: 'Istruzione',
        column: 'col-12 col-md-6',
        options: []
      }
    ],
  };

  constructor(
    private qualificationService: QualificationsService,
    private activatedRoute: ActivatedRoute,
    private _resources: ResourcesServices,
    public _contractService: ContractsService,
    private router: Router,
    private _utils: UtilsServices
  ) {

  }

  private _getQualficationsOptions() {
    this.qualificationService.getQualifications().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        this.resourceFormConfig.fields.find(el => el.key === 'qualification_id')!.options =
          [{ label: 'Scegli...', value: null }, ...response.data.map(el => { return { label: el.title, value: el.id } })];
      }
    });
  }

  ngOnInit() {
    this._getQualficationsOptions();
    //TRAMITE SUBSCRIBE AL SERVIZIO CHIAMIAMO LA FUNZIONE SUBMIT DEL COMPONENTE//
    this._utils.onSave.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value)
        this.onSubmit();
    })
  }



  onSubmit() {
    //IN DATA METTO I DUE FORMI DEI COMPONENTI FIGLI//
    let data = { ...this.resourceConf.resourceForm.formValue.value, ...this.contractDataForm.dataContractConfig.formValue.value };
    if (this.resourceConf.resourceForm.formValue.valid && this.contractDataForm.dataContractConfig.formValue.valid) {
      this._resources.createResource(data).subscribe({
        next: (response) => {
          toast.fire({
            icon: 'success',
            title: 'Risorsa creata',
            text: 'La risorsa è stata creata con successo'
          }).then(() => {
            this._resources.mode.next(Mode.SHOW);
            this.router.navigate([`app/resources/${response.data.resource.id}`])
          });
        }
      })
    } else {
      toast.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Compila tutti i campi'
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._resources.mode.next(Mode.SHOW);
  }
}
