import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CardComponent } from './card-list/card/card.component';
import { CardListComponent } from './card-list/card-list.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomFormComponent } from './custom-form/custom-form.component';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';

import { MatCardModule } from '@angular/material/card';
import { ExpenseItemsComponent } from './expense-items/expense-items.component';
import { TemplateDataComponent } from './template-data/template-data.component';
import { getItalianPaginatorIntl } from '../shared/helpers/italian-paginator-intl';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgZorroAntdModule } from '../modules/ng-zorro-antd/ng-zorro-antd.module';
import { CollapseComponent } from './collapse/collapse.component';
import { MatButtonModule } from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CardComponent,
    CardListComponent,
    BreadcrumbsComponent,
    CustomFormComponent,
    ExpenseItemsComponent,
    TemplateDataComponent,
    CollapseComponent,
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    MatTabsModule,
    ReactiveFormsModule,
    // MatStepperModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CurrencyMaskModule,
    MatSliderModule,
    MatCardModule,
    MatTooltipModule,
    NgZorroAntdModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule
  ],
  exports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    MatTabsModule,
    ReactiveFormsModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CardComponent,
    CardListComponent,
    CustomFormComponent,
    BreadcrumbsComponent,
    // MatStepperModule,
    MatSelectModule,
    MatChipsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ExpenseItemsComponent,
    TemplateDataComponent,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTooltipModule,
    NgZorroAntdModule,
    CollapseComponent,
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getItalianPaginatorIntl() }, DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ComponentsModule { }
