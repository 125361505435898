import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { Configuration } from './models/configuration.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { Field } from './models/field.model';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;
  @Input() config!: Configuration;
  @Input() function!: any;
  @Input() renderForm: BehaviorSubject<Configuration | null> = new BehaviorSubject<Configuration | null>(null);

  onLoad: boolean = true;
  selectedOptions: any[] = []
  auto: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  filteredItems!: Observable<string[]>



  selectedItem = new Array()
  selectedItemLabel = new Array()

  @Output() onFormCreate = new EventEmitter<any>();

  public loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  form: FormGroup = new FormGroup({});
  today = moment().format('YYYY-MM-DD');

  showPassword: boolean = false;

  constructor() {
    super();
  }

  override ngOnInit() {
    this.generateForm();
    this.renderForm.subscribe((res) => {
      if (res != null) {
        this.onLoad = false;
        this.config = res;
        this.generateForm();
        this.onLoad = true;
      }
    })
  }

  ngAfterViewInit(): void {
    this.form.get('project_partner')?.valueChanges.subscribe((value) => {
      this.addSelectedItemMulti(value)
    })

  }
  addSelectedItemMulti(item: any) {
    item.forEach((item: any) => {
      if (!this.selectedItem.includes(item)) {
        this.selectedItem.push(item)
        this.selectedItemLabel.push(item.label)
      }
    })

  }
  add(event: any, control: string): void {
    if (!this.selectedItem.includes(event.value)) {
      this.selectedItem.push(event)
      this.selectedItemLabel.push(event.name)
    } else {
      this.selectedItem.splice(event, 1)
      this.selectedItemLabel.splice(event.name, 1)
    }
    this.form.get(control)?.patchValue(this.selectedItem)
  }

  remove(selectedOption: any, control: string) {
    const index = this.selectedItemLabel.indexOf(selectedOption);
    if (index !== -1) {
      this.selectedItemLabel.splice(index, 1);
      this.selectedItem.splice(index, 1)
    }
    this.form.get(control)?.patchValue(this.selectedItem)
  }

  displayFn(item: any): string {    
    if (typeof (item) != 'object') {
      return item ? item : '';
    } else {
      return item.label ? item.label : ''
    }
  }

  displayFnMulti(item: any): string {
    return item.label ? item.label : ''
  }

  elementAuto(item: any, field: string) {
    let obj = {
      item: item,
      key: field
    }
    this.auto.next(obj)
  }

  get getElementAuto() {
    return this.auto.value
  }

  generateForm() {
    this.form = new FormGroup({});
    this.config.fields.forEach(field => {
      let validators = [];
      if (field?.validators?.includes('required')) validators.push(Validators.required);
      if (field?.min == 'now') field.min = this.today;
      if (field?.max == 'now') field.max = this.today;
      this.form.addControl(field?.key, new FormControl(field?.value ?? null, validators));
      if (field?.disabled) this.form.controls[field?.key].disable();
      if (field?.inputs) {
        field?.inputs?.forEach(input => {
          let inputsValidators = [];
          if (input?.validators?.includes('required')) inputsValidators.push(Validators.required);
          this.form.addControl(input.key, new FormControl(input?.value ?? null, inputsValidators))
        })
      }
      if (field.type == 'select-autocomplete') {
        field.filteredOptions = field.options;
      }
      if (field.type == 'multi-select-autocomplete') {
        field.filteredOptions = field.options;
      }
    });

    this.loaded.next(true);
    this.onFormCreate.emit();
  }

  onkeyUpChangeAutoComplete(search: string, field: Field) {
    field.filteredOptions = field.options?.filter(element => element.name?.toLowerCase().includes(search.toLowerCase()))
  }

  get formValue() {
    return this.form;
  }

  set formValue(form: any) {
    this.form.patchValue(form);
  }

  updateDisabledFields() {
    this.config.fields.forEach(field => {
      if (field?.disabled) this.form.controls[field?.key].disable();
      else this.form.controls[field?.key].enable();
    });
  }

  tooglePassword() {
    this.showPassword = !this.showPassword;
  }
}
