import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  /**
   *
   * @param message String or HTML
   * @param type success, error, warning, info, question
   * @param title Title of the alert
   * @param confirmButton Confirm button text
   * @param denyButton Deny button text
   * @returns
   */
  alert(
    message: string,
    type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
    title: string | null = null,
    confirmButton: string | null = null,
    denyButton: string | null = null
  ): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: title ?? '',
      html: message,
      icon: type,
      showConfirmButton: confirmButton ? true : false,
      confirmButtonText: confirmButton ?? 'Conferma',
      showDenyButton: denyButton ? true : false,
      denyButtonText: denyButton ?? 'Annulla',
      customClass: {
        confirmButton: "btn btn-primary py-2 px-3 mx-2",
        denyButton: "btn btn-secondary py-2 px-3 mx-2",
      },
      buttonsStyling: false,
      reverseButtons: true
    });
  }

  /**
   *
   * @param message String or HTML
   * @param type success, error, warning, info, question
   * @param title Title of the alert
   * @param confirmButton Confirm button text
   * @param denyButton Deny button text
   * @returns
   */
  timerAlert(
    message: string,
    type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
    title = null,
    confirmButton = null,
    denyButton = null
  ): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: title ?? '',
      html: message,
      icon: type,
      showConfirmButton: confirmButton ? true : false,
      confirmButtonText: confirmButton ?? 'Conferma',
      showDenyButton: denyButton ? true : false,
      timer: 1500,
      customClass: {
        confirmButton: "btn btn-primary p-2 mx-2",
        denyButton: "btn btn-outline-secondary p-2 mx-2",
      },
      buttonsStyling: false,
      reverseButtons: true
    });
  }
}
