import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AssignmentDataTable } from '../models/assignment.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../helpers/interceptor.service';


@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  route = `${environment.api}`;
  variiable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  variiableCrono: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  variabbleUpdateAssignment: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {

  }

  /** Notifica la modifica della DT pianificato */
  getUpdateRiepilogoPianificato() {
    return this.variiable;
  }

  setUpdateRiepilogoPianificato(variable: any) {
    this.variiable.next(variable);
  }

  /** Notifica la modifica del cronoprogramma */
  setUpdateCrono(variable: any) {
    this.variiableCrono.next(variable);
  }

  getUpdateCrono() {
    return this.variiableCrono;
  }

  /** Notifica la modifica dell'assegnazione */
  setvariabbleUpdateAssignment(variable: any) {
    this.variabbleUpdateAssignment.next(variable);
  }

  getVariabileUpdateAssigment() {
    return this.variabbleUpdateAssignment
  }


  getAssignmentDataTable(params: any): Observable<AssignmentDataTable> {
    return this.http.post<AssignmentDataTable>(`${this.route}/assignments`, params);
  }

  editAssignmentDataTable(assignmentDataTable: any) {
    return this.http.post<Response>(
      `${this.route}/assignments/update`,
      assignmentDataTable,
    )
  }

  deleteAssignedResources(resource_id: string, task_id: string) {
    return this.http.delete<Response>(
      `${this.route}/assignments/${resource_id}/${task_id}`,
    )
  }

  createAssignment(assignmentData: any) {
    return this.http.post<Response>(`${this.route}/assignments/store`, assignmentData);
  }

  exportExcelAssignment(params: any): Observable<Blob> {
    return this.http.post(`${this.route}/assignments/excel`, params, {
      responseType: 'blob'
    });
  }

  getDataTable(params: any) {
    return this.http.post<Response>(`${this.route}/assignments/dt`, params);
  }

  getMese(params: any) {
    return this.http.get<Response>(`${this.route}/assignments/meseanno`, { params });
  }

  getScheduledHoursByAssignmentId(assignment_id: string) {
    let config = new HttpContextConfig();
    config.noSpinner = true;
    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<string>>(`${this.route}/assignments/${assignment_id}/scheduledHours`, { context: context });
  }

  updateAssignment(params: any) {
    return this.http.post<Response>(`${this.route}/assignments/updateId`, params);
  }

  getFilterAssignment(projectId: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/projects/${projectId}/assignments/filter`)
  }

  getTooltipData(params: any) {
    let config = new HttpContextConfig();
    config.noSpinner = true;
    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<any>>(`${this.route}/assignments/suggestion`, { params, context: context })
  }


  storeResourceAssignment(projectId: string, start_date: any) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/projects/${projectId}/assignments/resource?start_date=${start_date}`);
  }

}




