import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';
import { Page } from 'src/app/shared/models/page.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { QualificationsService } from 'src/app/shared/services/qualifications.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { Configuration } from '../../../../../components/custom-form/models/configuration.model';
import { ResourceDataFormComponent } from '../../components/resource-data-form/resource-data-form.component';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-edit-resource.component',
  templateUrl: './edit-resource.component.html',
  styleUrls: ['./edit-resource.component.scss']
})
export class EditResourceComponent implements OnInit {
  @ViewChild('resourceConf') resourceConf!: ResourceDataFormComponent;

  private unsubscribe$ = new Subject<void>();
  type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
  id: string = this.activatedRoute.snapshot.params['resources'];
  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources' }
  ];


  resource: BehaviorSubject<Resource | null> = new BehaviorSubject<Resource | null>(null);

  options = new Array();


  resourceFormConfig: Configuration = {
    title: "Dati personali",
    fields: [
      {
        label: 'Cognome',
        key: 'last_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Cognome risorsa',
        column: 'col-12 col-md-6',
      },
      {
        label: 'Nome',
        key: 'first_name',
        type: 'text',
        validators: ['required'],
        placeholder: 'Nome risorsa',
        column: 'col-12 col-md-6',
      },
      {
        label: 'Codice fiscale',
        key: 'fiscal_code',
        type: 'text',
        validators: ['required'],
        placeholder: 'Codice fiscale',
        column: 'col-12 col-md-6',
        min: 16,
        max: 16,
      },
      {
        label: 'Data di nascita',
        key: 'birth_date',
        type: 'date',
        validators: ['required'],
        placeholder: 'Data di nascita',
        column: 'col-12 col-md-6'
      },
      {
        label: 'Email',
        key: 'email',
        type: 'email',
        validators: ['required'],
        placeholder: 'Email',
        column: 'col-12 col-md-6'
      },
      {
        label: 'Telefono',
        key: 'phone',
        type: 'tel',
        validators: ['required'],
        placeholder: 'Telefono',
        column: 'col-12 col-md-6'
      },
      {
        label: 'Titolo di studio',
        key: 'qualification_id',
        type: 'select',
        validators: ['required'],
        placeholder: 'Istruzione',
        column: 'col-12 col-md-6',
        options: []
      }
    ],
  };

  constructor(
    private qualificationService: QualificationsService,
    private activatedRoute: ActivatedRoute,
    public _resources: ResourcesServices,
    private router: Router
  ) {
  }

  private _getQualficationsOptions() {
    this.qualificationService.getQualifications().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        this.resourceFormConfig.fields.find(el => el.key === 'qualification_id')!.options =
          [{ label: 'Scegli...', value: null }, ...response.data.map(el => { return { label: el.title, value: el.id } })];
      }
    });
  }

 


  ngOnInit() {
    this._resources.resource.subscribe(response => {
      if (response?.id) {
        this.id = response?.id;
        this._getQualficationsOptions();
        //MI SOTTOSCRIVO AI CAMBIAMENTI//
        this._resources.mode.subscribe(response => {
          this.type.next(response);
        })
        this._resources.getResourceById(this.id).subscribe({
          next: (response) => {
            this.resource.next(response.data);
            this.routes.push({
              name: `${response.data.last_name} ${response.data.first_name}`,
              path: `app/resources/${response.data.id}/edit`,
              active: true
            });
          }
        })
      }
    })
  }

  ngOnDestroy() {
    this._resources.mode.next(Mode.SHOW);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  onSubmit() {
    let data = { ...this.resourceConf.resourceForm.formValue.value };
    if (this.resourceConf.resourceForm.formValue.valid) {
      this._resources.editResource(this.id, data).subscribe({
        next: (response) => {
          toast.fire({
            icon: 'success',
            title: 'Risorsa modificata',
            text: 'La risorsa è stata modificata con successo'
          }).then(() => {
            this.router.navigate([`app/resources/${response.data.id}`]);
            this._resources.mode.next(Mode.SHOW);
          });
        }
      })
    } else {
      toast.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Compila tutti i campi'
      });
    }
  }
}
