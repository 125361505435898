<mat-card *ngIf="data | async">
	<mat-card-content style="overflow-x: auto;">
		<mat-table [dataSource]="(data | async).timesheet.body" class="timesheet-table">
			<ng-container [matColumnDef]="headerElement" *ngFor="let headerElement of headerDef">
				<th mat-header-cell *matHeaderCellDef [attr.rowspan]="getFirstHeadRowSpan(headerElement.split(':')[1])"
					[attr.colspan]="getFirstHeadColSpan(headerElement.split(':')[1])"
					[style]="getFirstHeaderStyle(headerElement.split(':')[1])">
					{{(data | async).timesheet.header[headerElement.split(':')[1]]}}
				</th>
			</ng-container>

			<ng-container [matColumnDef]="subheaderElement"
				*ngFor="let subheaderElement of (data | async).timesheet.subheader">
				<th mat-header-cell *matHeaderCellDef class="{{getDayClass(subheaderElement.split(':')[1])}}">
					{{subheaderElement.split(':')[1]}}
				</th>
			</ng-container>

			<ng-container [matColumnDef]="bodyElement" *ngFor="let bodyElement of (data | async).timesheet.bodyColumns">
				<ng-container *ngIf="bodyElement == 'firstColumns'">
					<td mat-cell *matCellDef="let element" class="font-weight-bolder">
						<p>{{(element.firstColumns.code) ? element.firstColumns.code + ' - ' +
							element.firstColumns.titolo : element.firstColumns.titolo}}</p>
					</td>
					<td mat-footer-cell *matFooterCellDef class="font-weight-bolder">
						<p>{{(data | async).timesheet.footer.firstColumns.titolo}}</p>
					</td>
				</ng-container>
				<ng-container *ngIf="bodyElement == 'lastColumns'">
					<td mat-cell *matCellDef="let element" class="font-weight-bolder" style="padding: 0 !important;">
						<div class="d-flex justify-content-center">
							<span>{{ element.lastColumns.value }}</span>
							<div class="position-relative" *ngIf="element.firstColumns.assignment_id">
								<button class="info-button" nz-button nz-popover nzType="primary" [nzPopoverContent]="hintHours"
								(mouseover)="getHintValue(element.firstColumns.assignment_id)"><i class="fas fa-info-circle"></i></button>
							</div>
						</div>
					</td>
					<td mat-footer-cell *matFooterCellDef class="font-weight-bolder" style="padding: 0 !important;">
						<input style="width: 100%;" disabled
							[(ngModel)]="data.getValue().timesheet.footer.lastColumns.value" />
					</td>
				</ng-container>
				<ng-container *ngIf="bodyElement != 'firstColumns' && bodyElement != 'lastColumns'">
					<td mat-cell *matCellDef="let element" [class]="isDisabled(bodyElement)"
						style="padding: 0 !important;">
						<input maxlength="3" size="1" type="text" min="0" max="8" step="0.5" pattern="([0-8]{1}).(5{1})"
							style="box-sizing: content-box;" [disabled]="!element.days[getDay(bodyElement)].editable"
							[(ngModel)]="element.days[getDay(bodyElement)].value"
							(input)="doSum(element,bodyElement)" />
					</td>
					<td mat-footer-cell *matFooterCellDef class="{{isDisabled(bodyElement)}} font-weight-bolder"
						style="padding: 0 !important;"
						[matTooltip]="(data.getValue().timesheet.footer.days[getDay(bodyElement)].value > 8) ? 'Hai superato le 8 ore giornaliere!' : ''">
						<input disabled style="box-sizing: content-box;"
							[(ngModel)]="data.getValue().timesheet.footer.days[getDay(bodyElement)].value"
							[style]="(data.getValue().timesheet.footer.days[getDay(bodyElement)].value > 8) ? 'color:red;' : null" />
					</td>
				</ng-container>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="headerDef"></tr>
			<tr mat-header-row *matHeaderRowDef="(data | async).timesheet.subheader"></tr>
			<tr mat-row *matRowDef="let row; columns: (data | async).timesheet.bodyColumns"></tr>
			<tr mat-footer-row *matFooterRowDef="(data | async).timesheet.bodyColumns"></tr>
		</mat-table>

	</mat-card-content>
</mat-card>

<ng-template #hintHours>
	<div class="wrapper-spinner">
		<div class="overlay-spinner" style="position: inherit !important;" *ngIf="in_loading_hint$ | async">
			<div class="spinner-wrapper">
				<mat-progress-spinner color="primary" mode="indeterminate" diameter="25"></mat-progress-spinner>
			</div>
		</div>
		<span *ngIf="hint_assignment$| async">Ore pianificate: <strong>{{(hint_assignment$ | async)}}</strong></span>
	</div>
</ng-template>