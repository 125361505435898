import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { BehaviorSubject } from 'rxjs';
import { Mode } from '../models/utils.model';
import { ExpenseItems } from '../models/expense-items.model';
import { CronoMonths, Project } from '../models/project.model';
import { Company } from '../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  project: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
  mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
  expense_items: BehaviorSubject<ExpenseItems[]> = new BehaviorSubject<ExpenseItems[]>([]);
  budgets: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  budgetTableControls: any[] = [];

  route = `${environment.api}`;
  constructor(private http: HttpClient) { }

  createProject(data: Project) {
    return this.http.post<FormattedResponse<Project>>(`${this.route}/projects`, data)
  }

  createVariation(params: any) {
    let project_id = this.project.value?.id;
    return this.http.post<FormattedResponse<Project>>(`${this.route}/projects/${project_id}/variations/create`, params);
  }

  completeVariation(old_project_id: string, new_project_id: string, notes: string) {
    return this.http.post<FormattedResponse<Project>>(`${this.route}/projects/${old_project_id}/variations/${new_project_id}/complete`, { note: notes });
  }

  getProjects(params?: any) {
    return this.http.get<FormattedResponse<Project[]>>(`${this.route}/projects`, { params })
  }

  getProjectById(id: string) {
    return this.http.get<FormattedResponse<Project>>(`${this.route}/projects/${id}`)
  }

  getProjectForAssignment(id: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/projects/${id}/project-assignment`);
  }

  editProjectById(data: Project, id: string) {
    return this.http.put<FormattedResponse<Project>>(`${this.route}/projects/${id}`, data)
  }

  deleteProjectById(id: string) {
    return this.http.delete<FormattedResponse<Project>>(`${this.route}/projects/${id}`)
  }

  getDashboardReport(id: string, params: any) {
    return this.http.post<FormattedResponse<any>>(`${this.route}/projects/${id}/dashboard/reporting`, params);
  }

  getGoalsReport(id: string, expense_item_id: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/reports?project_id=${id}&expense_item_id=${expense_item_id}`);
  }

  getFinancingContributionReport(id: string, params: any) {
    return this.http.post<FormattedResponse<any>>(`${this.route}/projects/${id}/dashboard/financingContributionReporting`, params);
  }

  generateBudgetForm(id: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/projects/${id}/budgets/forms`)
  }

  saveBudgets(id: string, data: any) {
    return this.http.post<FormattedResponse<any>>(`${this.route}/projects/${id}/budgets`, data)
  }

  printAbstractProject(data: Project) {
    return this.http.get<any>(`${this.route}/projects/${data.id}/abstract`, {
      'headers': {
        'toast': 'hidden',
        'Content-Type': 'application/json', 'customHeader': 'blob'
      }, 'responseType': "blob" as "json"
    })
  }

  getPlannedData(id: string, params: any, type = 'personal') {
    return this.http.post<FormattedResponse<any>>(`${this.route}/projects/${id}/cronomonths/planned?type=${type}`, params)
  }

  spalmaBudget(id: string, params: any) {
    return this.http.post<FormattedResponse<any>>(`${this.route}/projects/${id}/cronomonths/budget`, params);
  }


  savePlannedData(projectId: string, event: any) {
    return this.http.put<FormattedResponse<any>>(`${this.route}/projects/${projectId}/cronomonths/planned`, { data: event })
  }
  exportPlannedData(projectId: string) {
    return this.http.get<Blob>(`${this.route}/projects/${projectId}/cronomonths/planned/export`, {
      headers: {
        'toast': 'hidden',
        'Content-Type': 'application/json',
        'customHeader': 'blob'
      },
      responseType: 'blob' as 'json'
    });
  }

  exportPDF(projectId: string, type: string) {
    return this.http.get<any>(`${this.route}/projects/${projectId}/cronomonths/planned/pdfexport?type=${type}&project_id=${projectId}`, {
      'headers': {
        'toast': 'hidden',
        'Content-Type': 'application/json', 'customHeader': 'blob'
      }, 'responseType': "blob" as "json"
    });
  }

  editDeviationLimit(id: string, data: number) {
    return this.http.put<FormattedResponse<number>>(`${this.route}/projects/${id}`, { deviation_limit: data })
  }

  getCronoMonths(projectId: string, expenseId: string, taskId: string) {
    return this.http.get<FormattedResponse<CronoMonths[]>>(`${this.route}/projects/${projectId}/cronomonths/month?expense=${expenseId}&task_id=${taskId}`);
  }

  getCompanyByProject(projectId: string, cronoMonth: Date) {
    return this.http.get<FormattedResponse<Company[]>>(`${this.route}/projects/${projectId}/company/cronoMonth/${cronoMonth}`);
  }

  getFilterCrono(projectId: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/projects/${projectId}/cronomonths/filter`)
  }

  getCronoMonthsBudget(projectId: string, params: any) {
    return this.http.post<FormattedResponse<any[]>>(`${this.route}/projects/${projectId}/cronomonths/budget`, params);
  }

  saveCronoMonthsBudget(projectId: string, params: any) {
    return this.http.post<FormattedResponse<any[]>>(`${this.route}/projects/${projectId}/cronomonths/save`, params);
  }

  getCriticalMonths(projectId: string) {
    return this.http.get<FormattedResponse<any>>(`${this.route}/assignments/critical/month?project_id=${projectId}`);
  }

  storeCriticalMonthsAssignment(params: any) {
    return this.http.post<FormattedResponse<any[]>>(`${this.route}/assignments/critical/month`, params);

  }
}
