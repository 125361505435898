import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/components/base/base.component';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { Page } from 'src/app/shared/models/page.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss']
})
export class ResourcesListComponent extends BaseComponent {
  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources', active: true }
  ];

  renderColumns: BehaviorSubject<string> = new BehaviorSubject<string>('');



  inUpdateCompany: BehaviorSubject<Resource | null> =
    new BehaviorSubject<Resource | null>(null);

  columns: string[] = [
    'last_name',
    'first_name',
    'last_contract.company.business_name',
    'last_contract.professional_positions.title',
  ];

  displayedColumns: string[] = [
    'Cognome',
    'Nome',
    'Azienda',
    'Posizione Professionale',
  ];

  sortableColumns: string[] = [
    'last_name',
    'first_name',
    'fiscal_code',
    'last_contract.company.company_sign',
  ];

  params = {

  };

  array_search: any;

  actionButtons = {
    info: { active: true, url: '' },
    modify: { active: false, url: 'edit' },
    trash: { active: true },
  }

  endpointDataSource: string = 'resources';

  constructor(
    private route: Router,
    public resourcesService: ResourcesServices,
    private alertService: AlertService
  ) {
    super();

    this.array_search = new Array();
    this.array_search.push("last_name", "first_name");
    this.params = {
      company_id: null,
      order_by: 'last_name',
      order_dir: 'asc',
      searchColumns: this.array_search
    };
  }

  updateElement(event: any) {
    this.resourcesService.getResourceById(event.id).subscribe(response => {
      this.resourcesService.resource.next(response.data);

      this.route.navigate(['app' + '/resources/' + response.data.id]);
    })
  }

  deleteElement(element: Resource): void {
    this.alertService.alert(
      `Sei sicuro di voler eliminare la risorsa <br><b>"${element.last_name} ${element.first_name}"</b>?`,
      'question',
      'Conferma eliminazione',
      'Conferma',
      'Annulla'
    ).then((result) => {
      if (result.isConfirmed) {
        this.resourcesService.deleteResource(element.id!).subscribe({
          next: (res) => {
            this.alertService.timerAlert(
              'Risorsa eliminata correttamente',
              'success'
            );
            this.renderColumns.next('reload');
          }
        });
      }
    });
  }
}
