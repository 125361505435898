import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company.model';
import { FormattedResponse } from '../models/formatted-response';
import { Mode } from '../models/utils.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../helpers/interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpClient) { }
  company: BehaviorSubject<Company | null> = new BehaviorSubject<Company | null>(null);
  mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


  getCompanies(params?: any): Observable<FormattedResponse<Company[]>> {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/companies`, { params });
  }


  getCompaniesByFilter(params?: any): Observable<FormattedResponse<Company[]>> {
    let config = new HttpContextConfig();
    config.noSpinner = true;
    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/companies`, { params, context: context });
  }

  getCompanyById(id: string): Observable<FormattedResponse<Company>> {
    return this.http.get<FormattedResponse<Company>>(`${environment.api}/companies/${id}`);
  }

  createCompany(company: Company): Observable<FormattedResponse<Company>> {
    return this.http.post<FormattedResponse<Company>>(`${environment.api}/companies`, company);
  }

  updateCompany(
    companyid: string,
    company: Company
  ): Observable<FormattedResponse<Company>> {
    return this.http.put<FormattedResponse<Company>>(`${environment.api}/companies/${companyid}`, company);
  }

  deleteCompany(companyid: Company['id']): Observable<FormattedResponse<null>> {
    return this.http.delete<FormattedResponse<null>>(`${environment.api}/companies/${companyid}`);
  }
}
