import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/modules/application/application.module').then(m => m.ApplicationModule) }, // , canActivate: [AuthGuard]
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '*', redirectTo: '' },
  { path: 'companies', loadChildren: () => import('./modules/application/companies/companies.module').then(m => m.CompaniesModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
