import { Component, Input } from '@angular/core';
import { Page } from 'src/app/shared/models/page.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  @Input() routes: Page[] = [];
}
