<div class="primary-container">
  <div class="d-flex justify-content-between">
    <h5>Distacchi</h5>
    <span class="btn btn-primary mb-4" (click)="addDistacco()">
      <i class="fa-solid fa-plus"></i> Aggiungi
    </span>
  </div>
  <div id="detachment-list-container" *ngIf="endpointDataSource">
    <drw-data-table [displayedColumns]="displayedColumns" [columns]="columns" [isFilter]="false" [actions]="true"
      [endpointDataSource]="endpointDataSource" [params]="params" [renderColumns]="renderColumns"
      [sortableColumns]="sortableColumns" (onDeleteClick)="deleteElement($event)"
      (onUpdateClick)="onUpdateEvent($event)"></drw-data-table>
  </div>

</div>