<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="d-flex flex-row justify-content-between">
      <a class=" navbar-brand" href="app/dashboard"><img src="../../../assets/quarzo-logo.svg"></a>

      <ul nz-menu nzMode="horizontal">
        <li class="fs-13" nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" [nzSelected]="page.selected">
          <span class nz-icon [nzType]="page.icon!"></span>
          {{ page.name }}
        </li>
      </ul>
    </div>

    <div class="btn-group me-5">
      <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
        aria-expanded="false">
        <button type="button" class="btn">
          <nz-avatar class="text-uppercase mx-2" [ngStyle]="{ 'background-color': '#FBEAF0', 'color': '#801B3D' }"
            [nzText]="currentUser?.email?.charAt(0)"></nz-avatar>
          <span>{{ currentUser?.email }}</span>
        </button>
      </button>

      <ul class="custom-dropdown dropdown-menu">
        <!-- **************** GESTIRE DATI RUOLO UTENTE **************** -->
        <li class="mb-3" *ngIf="(isAdminSubject|async)">
          <a class="d-block" routerLink="/app/administration-panel"><i
              class="btn fa-solid fa-table-cells-large text-muted"></i> Pannello di
            amministrazione</a>
        </li>
        <li class="mb-3">
          <a routerLink="/app/profile" class="d-block"><i class="btn fa-solid fa-gear text-muted"></i>
            Impostazioni</a>
        </li>
        <li>
          <i class="btn fa-solid fa-arrow-right-from-bracket text-muted"> </i><span class="cursor-pointer logout-w"
            (click)="logout()">Esci</span>
        </li>
      </ul>
    </div>
  </div>
</nav>