import { Component, OnInit } from '@angular/core';
import { Resource } from 'src/app/shared/models/resource.model';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { CmosServices } from 'src/app/shared/services/cmos.service';
import { Mode } from 'src/app/shared/models/utils.model';
import { ResourcesServices } from 'src/app/shared/services/resources.service';

@Component({
  selector: 'app-resource-cmo-list',
  templateUrl: './resource-cmo-list.component.html',
  styleUrls: ['./resource-cmo-list.component.scss']
})
export class ResourceCmoListComponent implements OnInit {
  id = this.route.snapshot.params['resource'];
  renderColumns: BehaviorSubject<string> = new BehaviorSubject<string>('');

  Mode: Mode = Mode.LIST;
  columns: string[] = [
    'project.name',
    'start_date',
    'end_date',
    'amount'
  ];

  displayedColumns: string[] = [
    'Progetto',
    'Data di inizio',
    'Data di fine',
    'Costo Medio Orario'
  ];

  sortableColumns: string[] = [
    'project.name',
    'start_date',
    'end_date'
  ];
  array_search = new Array();
  params: any;

  endpointDataSource;
  actionButtons = {
    info: { active: false },
    modify: { active: false },
    trash: { active: true },
  }


  constructor(
    private route: ActivatedRoute,
    private _cmos: CmosServices,
    private _resources: ResourcesServices,
    private alertService: AlertService,
    private router: Router) {
    this.id = this._resources.resource.value?.id;
    this.endpointDataSource = `resources/${this.id}/cmos`;
    this.array_search.push("business_name");

    this.params = {
      company_id: null,
      order_by: 'start_date',
      order_dir: 'desc',
      search_columns: this.array_search
    };
  }

  ngOnInit(): void {
    this.id = this._resources.resource.value?.id;
  }

  deleteElement(element: Resource): void {
    this.alertService
      .alert(
        `Sei sicuro di voler eliminare il CMO?`,
        'question',
        'Conferma eliminazione',
        'Conferma',
        'Annulla'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this._cmos.deleteCmo(this.id, element.id!).subscribe({
            next: (res) => {
              this.alertService.timerAlert(
                'CMO eliminato correttamente',
                'success'
              );
              this.renderColumns.next('reload');
            }
          });
        }
      });
  }

  addCmo() {
    this._cmos.mode.next(Mode.CREATE);
    this.router.navigate(['app/resources/' + this.id + "/cmos/new"]);
  }

  onUpdateEvent(event: any) {
    this.router.navigate([`/app/resources/${event.resource_id}/cmos/${event.id}`]);
  }
}
