import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { ProfessionalPosition } from '../models/professional-position';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalPositionsService {

  constructor(private http: HttpClient) { }

  getProfessionalPositions(): Observable<FormattedResponse<ProfessionalPosition[]>> {
    return this.http.get<FormattedResponse<ProfessionalPosition[]>>(`${environment.api}/professionalpositions`);
  }
}
