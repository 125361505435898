export const environment = {
  production: true,
  api: '/services/api/v1',
  general_ei_uuid: 'f2598ef1-410b-4b55-89ee-39ca1b2acfa9',
  personal_ei_uuid: 'af8caba9-cd17-42f2-a907-bf9971fc6774',
  personal_ei_ne_uuid: 'b097b8fc-f74b-47dd-a59e-e762b1d66098',
  personal_uuid: 'f097b8fc-f74b-47dd-a59e-e762b1d66098',
  consultancy_ei_uuid: '06789963-6c99-484e-96cf-35fdd340d7e2',
  tenant: '9f76daa6-b837-4dcc-805d-e1f096dc6b2a'
};
