import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { toast } from 'src/app/app.component';
import { Page } from 'src/app/shared/models/page.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { CmosServices } from 'src/app/shared/services/cmos.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { Configuration } from '../../../../../components/custom-form/models/configuration.model';
import { CmoDataFormComponent } from '../../components/cmo-data-form/cmo-data-form.component';
import { ResourceDataFormComponent } from '../../components/resource-data-form/resource-data-form.component';
import { Mode } from 'src/app/shared/models/utils.model';
import { UtilsServices } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-add-cmo.component',
  templateUrl: './add-cmo.component.html',
  styleUrls: ['./add-cmo.component.scss']
})
export class AddCmoComponent implements OnInit {
  @ViewChild('cmoConf') cmoConf!: CmoDataFormComponent;

  private unsubscribe$ = new Subject<void>();
  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources', active: false }
  ];
  id: string = "";
  type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.CREATE);
  cmo: any;
  counter_edit = 0;
  counter_add = 0;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private _resources: ResourcesServices,
    private _utils: UtilsServices,
    public _cmos: CmosServices,
    private router: Router
  ) {

  }

  ngOnInit() {
    this._resources.resource.subscribe(response => {
      if (response?.id) {
        this.id = response?.id;
        this._utils.onSave.pipe(takeUntil(this.unsubscribe$)).subscribe((value: any) => {
          if (value) {
            this.onSubmit();
          }
        })
        this._resources.getResourceById(this.id).subscribe({
          next: (response) => {
            this.routes.push({ name: `${response.data.last_name} ${response.data.first_name}`, path: `app/resources/${this.id}`, active: false });
            this.routes.push({ name: 'CMO', path: `app/resources/${this.id}`, active: false });
            this.routes.push({ name: 'Nuovo', path: `app/resources/${this.id}/cmos/new`, active: true });
            if (this.router.url.split('/').length > 4) {
              this.getCmo();
            }
          }
        })
      }
    })
  }

  getCmo() {
    this.cmo = this.router.url.split('/')[5];
    this._cmos.getCmoById(this.id, this.cmo).subscribe({
      next: (response) => {
        this.cmoConf.cmoForm.formValue = response.data;
      }
    })
  }


  ngAfterViewInit() {
    this._cmos.mode.subscribe(response => {
      this.type.next(response);
    })
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._cmos.mode.next(Mode.SHOW);
  }

  onSubmit() {
    if (this.type.value == Mode.CREATE && this.counter_add == 0) {
      let data = { ...this.cmoConf.cmoForm.formValue.value };
      if (this.cmoConf.cmoForm.formValue.valid) {
        this._cmos.createCmo(this.id, data).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (response) => {
            toast.fire({
              icon: 'success',
              title: 'Cmo creato',
              text: 'Il CMO è stato creato con successo'
            }).then(() => {
              this.counter_add = this.counter_add + 1;
              this._cmos.mode.next(Mode.SHOW);
              this.router.navigate([`app/resources/${this.id}`])
            });
          }
        })
      } else {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Compila tutti i campi'
        });
      }
    } else if (this.type.value == Mode.EDIT && this.counter_edit == 0) {
      let data = { ...this.cmoConf.cmoForm.formValue.value };
      if (this.cmoConf.cmoForm.formValue.valid) {
        this._cmos.editCmo(this.id, this.cmo, data).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (response: any) => {
            toast.fire({
              icon: 'success',
              title: 'Cmo creato',
              text: 'Il CMO è stato modificato con successo'
            }).then(() => {
              this.counter_edit = this.counter_edit + 1;
              this._cmos.mode.next(Mode.SHOW);
              this.router.navigate([`app/resources/${this.id}`])
            });
          }
        })
      } else {
        toast.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Compila tutti i campi'
        });
      }
    }
  }
}
