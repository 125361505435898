import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { Timesheet, TimesheetData } from 'src/app/shared/models/timesheet.model';
import { TimesheetService } from 'src/app/shared/services/timesheet.service';
import Swal from 'sweetalert2';
import { ResourceTimesheetDataComponent } from '../../components/resource-timesheet-data/resource-timesheet-data.component';
import { ResourcesServices } from 'src/app/shared/services/resources.service';

const month = ['Gennaio', 'Febraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];



@Component({
  selector: 'app-timesheet-details',
  templateUrl: './timesheet-details.component.html',
  styleUrls: ['./timesheet-details.component.scss']
})


export class TimesheetDetailsComponent implements OnInit {
  @ViewChild('timesheetData') timesheetData!: ResourceTimesheetDataComponent;

  timesheetData$: BehaviorSubject<TimesheetData | any> = new BehaviorSubject<TimesheetData | any>(null);
  resource_id: string = "";
  timesheet_id: string = ""



  routes: Page[] = [
    { name: 'Risorse', path: 'app/resources', active: false }
  ];

  constructor(
    private _timesheet: TimesheetService,
    private _resource: ResourcesServices,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    //MI PRENDO L'ID DEL SERVIZIO//
    this._resource.resource.subscribe(response=>{
      if(response?.id){
        this.resource_id = response.id;
        this.timesheet_id = this.route.snapshot.params['timesheet'];

        this._timesheet.getTimesheetByResourceId(this.resource_id, this.timesheet_id).subscribe
          ((res: any) => {
            this.timesheetData$.next(res.data);
            this.routes.push({ name: `${res.data.resource.last_name} ${res.data.resource.first_name}`, path: `app/resources/${res.data.resource.id}`, active: false });
            this.routes.push({ name: 'Timesheet', path: `app/resources/${res.data.resource.id}`, active: false });
            this.routes.push({ name: 'Info', path: `app/resources/${res.data.resource.id}/timesheet/details`, active: true }
            )
          })
      }
    })
   
  }


  printTimesheet() {
    this._timesheet.exportExcelTimesheet(this.resource_id,this.timesheet_id, this.timesheetData.data.value).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
  }


  updateTimesheet() {

    let timesheetData = this.timesheetData.data.value;
    this._timesheet.updateTimesheet(this.resource_id, this.timesheet_id, timesheetData).subscribe({
      next: (response) => {
        Swal.fire({
          icon: 'success',
          title: 'Timesheet aggiornato con successo',
        });
      },
      error: (response) => {
        Swal.fire({
          icon: 'error',
          text: response.error,
        });
      }
    }

    )

  }

  formatMonth(mesanno: string) {
    if (mesanno) {
      const parts = mesanno.split('-');
      return month[Number(parts[1]) - 1] + ' ' + parts[0];
    }
    return '';
  }

}
