export interface Params {
  type: string,
  page_index: number,
  page_size: number,
  order_by: string,
  order_dir: string,
  company_id?: string
}

export enum Type {
  COMPANIES = 'companies',
  COMPANY = 'company',
  PROJECTS = 'projects'
}

export enum Role {
  EMPLOYEE = 'employee',
  MANAGER = 'manager',
  EXECUTIVE = 'executive'
}

export interface StaticData {
  id: string;
  name: string;
  display_name: string;
}

export enum Mode {
  CREATE = 'create',
  SHOW = 'show',
  EDIT = 'edit',
  VARIATION = 'variation',
  LIST = 'list',
  CONFIGURATION = 'configuration',
  CREATE_CONFIGURATION = 'create_configuration',
  ADMIN_PANEL = 'admin_panel',
  ADMIN_PANEL_ADD_USER = 'admin_panel_add_user',
  REPORT = 'report'
}
