import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Cmo } from '../models/cmo.model';
import { BehaviorSubject } from 'rxjs';
import { Mode } from '../models/utils.model';

@Injectable({
    providedIn: 'root'
})
export class CmosServices {
    //VARIABILI PER GESTIRE IL CMO E IL MODE DEL FOOTER//
    cmo: BehaviorSubject<Cmo | null> = new BehaviorSubject<Cmo | null>(null);
    mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);

    route = `${environment.api}`;
    constructor(private http: HttpClient) { }

    getCmos(resource: string, params?: any) {
        return this.http.get<FormattedResponse<Cmo[]>>(`${this.route}/resources/${resource}/cmos`, { params });
    }

    getCmoById(resource: string, id: string) {
        return this.http.get<FormattedResponse<Cmo>>(`${this.route}/resources/${resource}/cmos/${id}`);
    }

    createCmo(resource: string, data: Cmo) {
        return this.http.post<FormattedResponse<Cmo>>(`${this.route}/resources/${resource}/cmos`, data);
    }

    editCmo(resource: string, id: string, data: Cmo) {
        return this.http.put<FormattedResponse<Cmo>>(`${this.route}/resources/${resource}/cmos/${id}`, data);
    }

    deleteCmo(resource: string, id: string) {
        return this.http.delete<FormattedResponse<Cmo>>(`${this.route}/resources/${resource}/cmos/${id}`);
    }
}
