import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomFormComponent } from 'src/app/components/custom-form/custom-form.component';
import { Configuration } from 'src/app/components/custom-form/models/configuration.model';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { ContractsService } from 'src/app/shared/services/contracts.service';
import { toast } from 'src/app/app.component';
import { ProfessionalPositionsService } from 'src/app/shared/services/professional-positions.service';
import { BehaviorSubject } from 'rxjs';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-contract-data-form',
  templateUrl: './contract-data-form.component.html',
  styleUrls: ['./contract-data-form.component.scss']
})
export class ContractDataFormComponent {

  @ViewChild('dataContractConfig') dataContractConfig!: CustomFormComponent;
  @Input('type') type: string = "";
  @Input('type_mod') type_mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);

  resourceContractFormConfig: Configuration | null = null;
  id: string = this.activatedRoute.snapshot.params['resource'];
  contractId: string = this.activatedRoute.snapshot.params['contract'];



  constructor(
    private activatedRoute: ActivatedRoute,
    private professionalPositionService: ProfessionalPositionsService,
    private contractsService: ContractsService,
    private companiesService: CompaniesService,
  ) {
    this.resourceContractFormConfig = {
      title: "Contratto",
      fields: [
        {
          "label": "Stato Contratto",
          "key": "state_contracts",
          "type": "select",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "placeholder": "Seleziona una mansione",
          "options": [{ label: "In corso", value: "Ready" },
          { label: "Previsto", value: "Expected" },
          { label: "Cessato", value: "Finish" }
          ],
          "column": "col-12 col-lg-3"
        },
        {
          "label": "Data inizio",
          "key": "start_date",
          "type": "date",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "min": null,
          "max": null,
          "placeholder": "ES. 01/01/2020",
          "column": "col-12 col-lg-3",
        },
        {
          "label": "Data fine",
          "key": "end_date",
          "type": "date",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "min": null,
          "max": null,
          "placeholder": "ES. 01/01/2020",
          "column": "col-12 col-lg-3"
        },
        {
          "label": "",
          "key": "",
          "type": "empty",
          "validators": [],
          "hint": "",
          "placeholder": "",
          "column": "col-12 col-lg-2"
        },
        {
          "label": "Mansione",
          "key": "professional_position",
          "type": "select",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "placeholder": "Seleziona una mansione",
          "options": [],
          "column": "col-12 col-lg-3"
        },
        {
          "label": "Ore mensili",
          "key": "month_hours",
          "type": "number",
          "validators": [
          ],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "placeholder": "Ore mensili",
          "column": "col-12 col-lg-2",
          "value": 0
        },
        {
          "label": "Costo effettivo",
          "key": "effective_cost",
          "type": "currency",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "placeholder": "Inserisci il costo",
          "column": "col-12 col-lg-2",
          "fiveCurrency": true,
          "value": 0
        },
        {
          "label": "Azienda proprietaria della risorsa",
          "key": "owner_company",
          "type": "select",
          "validators": [],
          "disabled": this.type_mode.value == Mode.SHOW ? true : false,
          "hint": null,
          "placeholder": "Seleziona un'azienda",
          "options": [],
          "column": "col-12 col-lg-3"
        },
      ]
    }
  }

  ngOnInit() {
    this._getContractById();
    this.getCompaniesOptions();
    this.getProfessionalPositionOptions();
  }

  ngAfterViewInit() {
    // //MI SOTTOSCRIVO AI CAMBIAMENTI DEL TYPE PASSATO E MODIFICO IL CAMPO DISABLED//
    this.type_mode.subscribe(response => {
      this.resourceContractFormConfig?.fields.forEach((field: any) => {
        field.disabled = response == Mode.SHOW ? true : false;
      });
      this.dataContractConfig.updateDisabledFields();
      this.dataContractConfig?.formValue.get('start_date')!.valueChanges.subscribe((value: any) => {
        let findStartEl = this.resourceContractFormConfig?.fields.find((el: any) => el.key == 'end_date');
        findStartEl ? findStartEl.min = value : null;
      })

      this.dataContractConfig?.formValue.get('end_date')!.valueChanges.subscribe((value: any) => {
        if (this.dataContractConfig.formValue.get('start_date').value == null && response == Mode.EDIT) {
          toast.fire({
            icon: 'error',
            title: 'Errore',
            text: "Seleziona prima una data d'inizio"
          });
        }
      })
    })
  }





  getCompaniesOptions() {
    this.companiesService.getCompanies({ type: 'company' }).subscribe({
      next: (response) => {
        // * aggiungo le options per la select 'owner_company'
        this.resourceContractFormConfig!.fields.find(el => el.key === 'owner_company')!.options = [
          { label: "Seleziona un'azienda", value: null }, ...response.data.map(el => { return { label: el.business_name, value: el.id } })
        ];
      }
    });
  }

  getProfessionalPositionOptions() {
    this.professionalPositionService.getProfessionalPositions().subscribe({
      next: (response) => {
        // * aggiungo le options per la select 'professional_position'
        this.resourceContractFormConfig!.fields.find(el => el.key === 'professional_position')!.options =
          [{ label: 'Seleziona una mansione', value: null }, ...response.data.map(el => { return { label: el.title, value: el.id } })];
      }
    });
  }

  _getContractById() {
    if (this.type_mode.value == 'show' || this.type_mode.value == "edit") {
      this.contractsService.getContractById(this.id, this.contractId).subscribe({
        next: (response) => {
          this.dataContractConfig.formValue = response.data[0];
          if (this.type == 'show') {
            this.dataContractConfig.form.disable();
          }
        }
      })
    }
  }
}
