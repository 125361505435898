<div class="primary-container">
    <div class="d-flex justify-content-between">
        <h5>Contratti</h5>
        <span class="btn btn-primary mb-4" (click)="addContract()">
            <i class="fa-solid fa-plus"></i> Aggiungi
        </span>
    </div>
    <div id="contract-resource-container" class="m-2">
        <drw-data-table #contractsTable [displayedColumns]="displayedColumns" [columns]="columns"
            [renderColumns]="renderColumns" [endpointDataSource]=" 'resources/' + id + '/contracts-resource' "
            [params]="params" [actions]="true" [actionsButton]="actionButtons" (onUpdateClick)="infoElement($event)"
            (onDeleteClick)="deleteElement($event)"></drw-data-table>
    </div>
</div>