import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { Cmo } from 'src/app/shared/models/cmo.model';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { ProfessionalPositionsService } from 'src/app/shared/services/professional-positions.service';
import { QualificationsService } from 'src/app/shared/services/qualifications.service';
import { CmosServices } from 'src/app/shared/services/cmos.service';
import { CustomFormComponent } from '../../../../../components/custom-form/custom-form.component';
import { Configuration } from '../../../../../components/custom-form/models/configuration.model';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { Field } from 'src/app/components/custom-form/models/field.model';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { toast } from 'src/app/app.component';
import { Mode } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-cmo-data-form',
  templateUrl: './cmo-data-form.component.html',
  styleUrls: ['./cmo-data-form.component.scss']
})
export class CmoDataFormComponent extends BaseComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('cmoForm') cmoForm!: CustomFormComponent;

  @Input() cmo: BehaviorSubject<Cmo | null> = new BehaviorSubject<Cmo | null>(null);
  @Input() disabled = false;
  @Input() type: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);


  cmoFormConfig: Configuration | null = null;

  constructor(
    private _projects: ProjectsService,
    private _resources: ResourcesServices,
    private _cmos: CmosServices
  ) {
    super();
  }

  override ngOnInit() {
    this.createConfig();
    this._getProjects();
  }

  ngAfterViewInit() {
    this.cmo.subscribe(value => {
      this.cmoForm!.formValue = value;
    });
    this.type.subscribe(value => {
      this.cmoFormConfig?.fields.forEach((field: any) => {
        field.disabled = value == Mode.SHOW ? true : false;
      });
      this.cmoForm.updateDisabledFields();
    })
  }

  private _getProjects() {
    let params = {
      company_id: null,
      order_by: 'name',
      order_dir: 'asc',
      skip: 0,
      take: 15
    };

    this._projects.getProjects(params).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        let projects = [{ name: 'Seleziona progetto', label: "Seleziona progetto", value: null }, ...this.optionsMap(response.data, ['name'], ['name'], 'id')];
        this.cmoFormConfig!.fields.find((field: Field) => field.key == 'project_id')!.options = projects;
      }
    });
  }

  createConfig() {
    this.cmoFormConfig = {
      title: "Costo Medio Orario",
      fields: [
        {
          label: 'Progetto',
          key: 'project_id',
          type: 'select',
          validators: ['required'],
          placeholder: 'Progetto',
          column: 'col-12 col-md-6',
          disabled: this.type.value == Mode.SHOW ? true : false,
        },
        {
          label: 'Costo',
          key: 'amount',
          type: 'currency',
          validators: ['required'],
          placeholder: 'Costo',
          column: 'col-12 col-md-6',
          disabled: this.type.value == Mode.SHOW ? true : false,
          fiveCurrency: true,
          value: 0,
          step: 0.01,
        },
        {
          label: 'Data di inizio',
          key: 'start_date',
          type: 'date',
          validators: ['required'],
          placeholder: 'Data di inizio',
          column: 'col-12 col-md-6',
          disabled: this.type.value == Mode.SHOW ? true : false,
        },
        {
          label: 'Data di fine',
          key: 'end_date',
          type: 'date',
          validators: ['required'],
          placeholder: 'Data di fine',
          column: 'col-12 col-md-6',
          disabled: this.type.value == Mode.SHOW ? true : false,
        },
      ]
    };
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
