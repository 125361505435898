<div class="primary-container">
  <div class="d-flex justify-content-between">
    <h5>Costo Medio Orario</h5>
    <span class="btn btn-primary mb-4" (click)="addCmo()">
      <i class="fa-solid fa-plus"></i> Aggiungi
    </span>
  </div>

  <div id="resource-cmo-container" class="m-2">
    <drw-data-table [displayedColumns]="displayedColumns" [columns]="columns" [isFilter]="false" [actions]="true"
      [endpointDataSource]="endpointDataSource" [params]="params" [renderColumns]="renderColumns"
      [sortableColumns]="sortableColumns" (onDeleteClick)="deleteElement($event)"
      (onUpdateClick)="onUpdateEvent($event)"></drw-data-table>
  </div>
</div>